import { useState } from 'react'
import { useQueryClient } from 'react-query'
import type { FC } from 'react'
import { Box, Grid, Divider, Radio } from '@mui/material'
import { Steerage, SteerageProvider } from '../types'
import SteerageProviderListItem from '../SteerageProviderListItem'
import Loader from '~/components/Loader'
import { useUnselectSteerageProvider } from '../../../../api/SteerageService'
import { listSteerageSteerageProvidersKey } from '~/api/SteerageService'
import MemberSelectedView, { selectedProvider } from '../MemberSelectedView'

interface SteerageProps {
  steerage: Steerage
  steerageProviders?: Array<SteerageProvider> | null | undefined
  setShowEditProviderForm: (providerId: number | null) => void
  showEditProviderForm: number | null
  showAddProviderForm: boolean
  setShowAddProviderForm: (showAddProviderForm: boolean) => void
  validReasonsForException
  isWaiverToggleEnabled?: boolean | null
}

export const SteerageProviderList: FC<SteerageProps> = props => {
  const steerage = props?.steerage
  const client = useQueryClient()
  const [memberSelectedProvider, setMemberSelectedProvider] = useState<number | null>(
    selectedProvider(steerage?.steerageProviders)?.id ?? null
  )

  //API's for selecting/unselecting the steerage provider on behalf of a member
  const { isLoading: isUnselectingProvider, mutateAsync: handleUnselectingSteerageProvider } =
    useUnselectSteerageProvider()

  const handleMemberSelectedProviderChange = () => {
    let payload = {
      steerageId: props?.steerage?.id ?? null,
      providerId: null,
    }
    setMemberSelectedProvider(null)
    // unselecting the steerage provider
    handleUnselectingSteerageProvider(payload).then(async () => {
      // invalidate queries for refetching the providers
      await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
    })
  }

  return (
    <Box mt={1}>
      {props?.steerageProviders && props?.steerageProviders?.length > 0 && (
        <Box mt={1}>
          <Divider />
        </Box>
      )}
      {props?.steerageProviders?.map(provider => (
        <SteerageProviderListItem
          key={provider?.id}
          steerage={steerage}
          provider={provider}
          setShowEditProviderForm={props.setShowEditProviderForm}
          showEditProviderForm={props.showEditProviderForm ?? null}
          showAddProviderForm={props.showAddProviderForm}
          setShowAddProviderForm={props.setShowAddProviderForm}
          validReasonsForException={props.validReasonsForException}
          memberSelectedProvider={memberSelectedProvider}
          setMemberSelectedProvider={setMemberSelectedProvider}
          isWaiverToggleEnabled={props?.isWaiverToggleEnabled}
        />
      ))}
      {/* Only show the radio buttons when it is locked and length of provider is greater then one */}
      {steerage?.isLocked && props?.steerageProviders && props?.steerageProviders?.length > 1 && (
        <Box mt={1} mb={1}>
          {isUnselectingProvider ? <Loader /> : null}
          <Grid container>
            <Grid item xs={1}>
              <Radio
                checked={memberSelectedProvider ? false : true}
                value={null}
                name="radio-buttons"
                onChange={handleMemberSelectedProviderChange}
              />
            </Grid>
            <Grid item xs={11}>
              <Box mt={1}>No provider is selected</Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      )}

      {steerage?.isLocked && props?.steerageProviders && (
        <MemberSelectedView
          steerageProviders={props?.steerageProviders}
          steerage={props.steerage}
        />
      )}
    </Box>
  )
}
