import { FC, useCallback, useState } from 'react'
import './styles.css'
// => Tiptap packages
import { useEditor, EditorContent, Editor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import Underline from '@tiptap/extension-underline'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'

import { Box, Button, TextField, Typography } from '@mui/material'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined'
import LinkIcon from '@mui/icons-material/Link'
import FFModal from '../FFModal/FFModal'
import CloseIcon from '@mui/icons-material/Close'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import { GREY } from '~/utils/theme'

interface RichTextEditorProps {
  isLoading: boolean
  content: string
  onSubmit: (content: string) => {}
  onCancel: () => void
}

export const RichTextEditor: FC<RichTextEditorProps> = props => {
  const [content, setContent] = useState('')
  const editor = useEditor({
    extensions: [
      Placeholder.configure({ placeholder: 'Enter your message' }),
      Document,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false,
      }),
      Bold,
      Underline,
      Italic,
      Strike,
      OrderedList,
      BulletList,
      ListItem,
    ],
    content: content,
    editorProps: {
      attributes: {
        class: 'focus:outline-none',
      },
    },
    autofocus: false,
    onUpdate({ editor }) {
      setContent(editor.getHTML())
    },
  }) as Editor
  const [modalIsOpen, setIsOpen] = useState(false)
  const [url, setUrl] = useState<string>('')

  const openModal = useCallback(() => {
    setUrl(editor.getAttributes('link').href)
    setIsOpen(true)
  }, [editor])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setUrl('')
  }, [])

  const saveLink = useCallback(() => {
    if (url) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run()
    } else {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
    }
    closeModal()
  }, [editor, url, closeModal])

  const removeLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run()
    closeModal()
  }, [editor, closeModal])

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run()
  }, [editor])

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run()
  }, [editor])

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run()
  }, [editor])

  const toggleUnOrderedList = useCallback(() => {
    editor.chain().focus().toggleBulletList().run()
  }, [editor])

  const toggleOrderedList = useCallback(() => {
    editor.chain().focus().toggleOrderedList().run()
  }, [editor])

  const onSubmit = async () => {
    if (editor.getHTML().length !== 0) {
      props.onSubmit(editor.getHTML())
      setContent('')
      editor.commands.clearContent()
    }
  }
  const onCancel = () => {
    editor.commands.clearContent()
    props.onCancel()
  }

  if (!editor) {
    return null
  }

  return (
    <Box flex={1}>
      <Box flex={1} borderRadius={1} border={1} borderColor={GREY[200]} mt={3}>
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'auto',
            height: '180px',
            maxWidth: '550px',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
          }}
        >
          <EditorContent editor={editor} />
        </Box>
        <Box borderTop={1} borderColor={GREY[200]}>
          <Button
            onClick={toggleBold}
            variant={editor.isActive('bold') ? 'contained' : 'text'}
            color="secondary"
          >
            <FormatBoldIcon color="primary" />
          </Button>
          <Button
            variant={editor.isActive('underline') ? 'contained' : 'text'}
            onClick={toggleUnderline}
            color="secondary"
          >
            <FormatUnderlinedIcon color="primary" />
          </Button>
          <Button
            onClick={toggleItalic}
            variant={editor.isActive('italic') ? 'contained' : 'text'}
            color="secondary"
          >
            <FormatItalicIcon color="primary" />
          </Button>
          <Button
            onClick={toggleUnOrderedList}
            variant={editor.isActive('bulletList') ? 'contained' : 'text'}
            color="secondary"
          >
            <FormatListBulletedIcon color="primary" />
          </Button>
          <Button
            variant={editor.isActive('orderedList') ? 'contained' : 'text'}
            color="secondary"
            onClick={toggleOrderedList}
          >
            <FormatListNumberedIcon color="primary" />
          </Button>
          <Button
            variant={editor.isActive('link') ? 'contained' : 'text'}
            onClick={openModal}
            color="secondary"
          >
            <LinkIcon color="primary" />
          </Button>
        </Box>
      </Box>
      <FFModal open={modalIsOpen}>
        <Box m={2}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" pb={3}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h6" align="center">
                Add link
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Button className="modal-close" type="button" onClick={closeModal}>
                <CloseIcon color="primary" />
              </Button>
            </Box>
          </Box>
          <TextField
            fullWidth
            label="Enter the URL"
            defaultValue={url}
            variant="outlined"
            onChange={e => setUrl(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end" pt={3}>
            <Button className="button-remove" type="button" onClick={removeLink}>
              Remove
            </Button>
            <Button className="button-save" type="button" onClick={saveLink}>
              Save
            </Button>
          </Box>
        </Box>
      </FFModal>
      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        justifyItems="end"
        alignItems="center"
        mt={3}
      >
        <Button
          color="primary"
          variant="text"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{
            borderRadius: 10,
            fontSize: '1.4rem',
          }}
          onClick={onSubmit}
          disabled={props.isLoading}
        >
          Submit
        </Button>
      </Box>
    </Box>
  )
}
