import { FC } from 'react'
// => Tiptap packages
import { EditorContent, Editor } from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'
import Bold from '@tiptap/extension-bold'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import Underline from '@tiptap/extension-underline'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import ListItem from '@tiptap/extension-list-item'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'

import DOMPurify from 'dompurify'

import { ALLOWED_ATTR, ALLOWED_TAGS } from './utils'

interface RichTextEditorDisplayProps {
  content: string
  maxLength?: number
}

const truncateEditorContent = (content, maxLength) => {
  let length = 0
  const traverse = node => {
    if (!node || length >= maxLength) return null
    if (node.type === 'text') {
      if (length + node.text.length > maxLength) {
        node.text = node.text.slice(0, maxLength - length) + '...' // Truncate
      }
      length += node.text.length
    } else if (node.content) {
      node.content = node.content.map(traverse).filter(Boolean)
    }
    return node
  }
  return traverse(JSON.parse(JSON.stringify(content))) // Deep copy before modification
}

export const RichTextEditorDisplay: FC<RichTextEditorDisplayProps> = props => {
  const santisedContent = DOMPurify.sanitize(props.content, {
    ALLOWED_TAGS: ALLOWED_TAGS,
    ALLOWED_ATTR: ALLOWED_ATTR,
  })
  const editor = new Editor({
    content: santisedContent,
    extensions: [
      Document,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false,
      }),
      Bold,
      Underline,
      Italic,
      Strike,
      OrderedList,
      BulletList,
      ListItem,
      TextStyle,
      Color,
    ],
    editable: false,
  })
  if (props.maxLength) {
    const truncatedContent = truncateEditorContent(editor.getJSON(), props.maxLength) // Limit to 100 characters
    editor.commands.setContent(truncatedContent)
  }
  return <EditorContent editor={editor} />
}
