import { Box, Typography } from '@mui/material'
import ProgramStatusStepper from './ProgramStatusStepper'

const ProgramDetail = ({ program, enrollments, patient }) => {
  return (
    <Box mt={3}>
      <Typography variant="body1">{program.displayName}</Typography>
      <Box mt={1}>
        <ProgramStatusStepper program={program} enrollments={enrollments} patient={patient} />
      </Box>
    </Box>
  )
}

export default ProgramDetail
