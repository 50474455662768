import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  BoxProps,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import {
  AutoAwesome as AutoAwesomeIcon,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material'
import invariant from 'tiny-invariant'
import { useFormik } from 'formik'
import { format } from 'date-fns'

import {
  EditSteerageInterface,
  GetElationLetterTemplateInterface,
  useEditSteerage,
  useGetElationLetterTemplate,
} from '~/api/SteerageService'
import {
  ElationReferralTempate,
  Service,
  ReferralPriority,
  Steerage,
  MemberServiceLevelConfig,
  ClinicalFocusArea,
} from '../types'
import { useReferralDetails } from './utils'
import PersonPreference from '../PersonPreference'
import ServiceAutocomplete from './ServiceAutocomplete'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'
import ClinicalFocusAreaAutocomplete from './ClinicalFocusAreaAutocomplete'
const SEARCH_TYPES = {
  specialty: 'SpecialtyGroup' as const,
  facility: 'LocationType' as const,
} as const

export const ReferralRequest: React.FC<{ steerage: Steerage }> = props => {
  const steerage = props.steerage
  const { isLoading: referralDetailsIsLoading, ...referralDetails } = useReferralDetails()
  const editSteerage = useEditSteerage()
  const getElationTemplate = useGetElationLetterTemplate()
  const isLoading = referralDetailsIsLoading || editSteerage.isLoading

  // Prepopulate the form with the specialtyGroup or locationType
  const initialSpecialtyLocationType = (steerage?.specialtyGroup || steerage?.locationType) ?? 0
  const initialSpecialtyLocationsource = steerage?.specialtyGroup
    ? SEARCH_TYPES.specialty
    : steerage?.locationType
    ? SEARCH_TYPES.facility
    : null
  const selectedInitialSpecialtyLocationType =
    referralDetails?.specialtiesAndLocationTypes?.find(
      s => s.id === initialSpecialtyLocationType && s?.source === initialSpecialtyLocationsource
    ) ?? null
  const initialSpecialtyLocationTypeLabel = selectedInitialSpecialtyLocationType?.label ?? null
  let virtualAppropriateBasedOnSpecialty = selectedInitialSpecialtyLocationType
    ? selectedInitialSpecialtyLocationType.doesVirtualPartnerExist
    : false

  const useFacilitySearchTab = useFeatureSwitch('feature.referrals.crm_facility_search')?.active
  const selfServiceEnabled = useFeatureSwitch('feature.referrals.self_service')?.active

  // Prepopulate isLastMile and notifyClinicianYn so they render as a string
  let initialIsLastMile: null | string =
    initialSpecialtyLocationTypeLabel === 'Firefly Nearby'
      ? 'YES'
      : initialSpecialtyLocationTypeLabel
      ? 'NO'
      : null
  let initialNotifyClinicianYn: null | string = null
  if (steerage?.notifyClinicianYn === true) initialNotifyClinicianYn = 'YES'

  /**
   * There are two scenarios for showing the referral section to the ops
   * 1. If member is switched from (care or Care+Coverage) to Coverage only then we need to show the past referral details
   * 2. For new referral
   * For the 1st case where member switched the program, we need to add extra condition where referral is present to ensure
   * that ops can see the past referral details
   */
  const shouldHideReferral =
    steerage?.memberServiceLevel == MemberServiceLevelConfig.COVERAGE &&
    steerage?.referral?.isActive != true

  const [searchTypeTab, setSearchTypeTab] = useState<string>()

  // Set the tab to the specialty/location source based on preset value
  // If there is none, then set to specilaty as default
  useEffect(() => {
    if (useFacilitySearchTab) {
      if (initialSpecialtyLocationsource === SEARCH_TYPES.facility) {
        setSearchTypeTab(SEARCH_TYPES.facility)
      } else {
        setSearchTypeTab(SEARCH_TYPES.specialty)
      }
    }
  }, [initialSpecialtyLocationsource, useFacilitySearchTab])

  const formik = useFormik({
    // This allows formik to reinitialize when steerage changes
    enableReinitialize: true,
    initialValues: {
      preferredProviderOrFacility: steerage?.preferredProviderOrFacility ?? '',
      specialtyGroup: initialSpecialtyLocationTypeLabel,
      priority: steerage?.priority ?? '',
      typeOfVisit: steerage?.typeOfVisit ?? 'Any',
      isLastMile: initialIsLastMile,
      services: steerage?.services ?? [],
      clinicalFocusAreas: steerage?.clinicalFocusAreas ?? [],
      notifyClinicianYn: initialNotifyClinicianYn,
      sendToElation: false,
      referralBody: steerage?.referral?.body ?? '',
      isReferralNotActive:
        steerage?.referral?.isActive != null
          ? !steerage?.referral?.isActive
          : steerage?.segment
          ? true
          : false,
    },
    onSubmit: values => {
      // Coerce the isLastMile value to a boolean
      let isLastMile: boolean | null = null
      if (values.isLastMile === 'YES') isLastMile = true
      else if (values.isLastMile === 'NO') isLastMile = false

      // Coerce the notifyClinicianYn value to a boolean
      let notifyClinicianYn: boolean | null = null
      if (values.notifyClinicianYn === 'YES') notifyClinicianYn = true
      else if (values.notifyClinicianYn === 'NO') notifyClinicianYn = false

      // Fetch the ServiceIds
      let serviceIds: number[] = []
      serviceIds = values.services.map(service => service.id)

      // If there are no services selected, it may be because the user selected a specialty that has only one service.
      // In that case, we need to select that service
      if (values.services.length === 0) {
        const specialty = specialtyGroupsAndLocationTypeOptions.find(
          s => s.label === values.specialtyGroup
        )
        if (specialty?.services?.length === 1) {
          serviceIds = [specialty.services[0].id]
        }
      }

      // Fetch the Clinical Focus Area IDs
      const clinicalFocusAreaIds = values.clinicalFocusAreas.map(
        clinicalFocusArea => clinicalFocusArea.id
      )

      let field = 'specialtyGroup'
      let payload: EditSteerageInterface = {
        steerageId: steerage.id,
        priority: values.priority,
        typeOfVisit: virtualAppropriateBasedOnSpecialty == true ? values.typeOfVisit : 'Any',
        specialtyGroup: null, // these should be set in the if condition below, null is the default to clear
        locationType: null,
        memberRequestedData: {
          providerName: values?.preferredProviderOrFacility,
        },
        serviceIds: serviceIds,
        clinicalFocusAreaIds: clinicalFocusAreaIds,
      }

      payload['preferredProviderOrFacility'] = values.preferredProviderOrFacility
      if (!shouldHideReferral) {
        payload['notifyClinicianYn'] = notifyClinicianYn
        // TODO: Update this type to be Partial<Referral>
        // @ts-ignore
        payload['referral'] = {
          isLastMile,
          body: values.referralBody,
          isActive: !values.isReferralNotActive,
        }
        payload['sendToElation'] = values?.sendToElation
      }
      if (values.specialtyGroup) {
        // Check whether the specialtyGroup value is a location or a specialty
        // Default to specialtyGroup just to make the types happy

        const chosenSpecialty = referralDetails?.specialtiesAndLocationTypes!.find(
          s => s.label === values.specialtyGroup
        )
        invariant(chosenSpecialty, 'Chosen specialty not found')
        if (chosenSpecialty.source === SEARCH_TYPES.specialty) field = 'specialtyGroup'
        if (chosenSpecialty.source === SEARCH_TYPES.facility) field = 'locationType'
        payload[field] = chosenSpecialty.id
      }

      // Update the API and reset the form values
      editSteerage.mutateAsync(payload, {
        // Use new initial values to reset the form
        onSuccess: () => formik.resetForm(),
      })
    },
  })

  const handleCreateReferralTemplateClick = () => {
    if (steerage?.person) {
      let isLastMile: boolean | null = null
      let field = 'specialtyGroup'
      if (formik.values.isLastMile === 'YES') isLastMile = true
      else if (formik.values.isLastMile === 'NO') isLastMile = false

      let payload: GetElationLetterTemplateInterface = {
        personId: steerage?.person,
        isLastMile: isLastMile,
        priority: formik.values.priority,
        locationType: null,
        specialtyGroup: null,
        typeOfVisit: virtualAppropriateBasedOnSpecialty == true ? formik.values.typeOfVisit : 'Any',
        preferredProvider: formik.values.preferredProviderOrFacility,
      }

      if (isLastMile) {
        const serviceDescriptions = formik.values.services
          .map(service => service.description)
          .toString()
        payload['services'] = serviceDescriptions
      }

      if (formik.values.specialtyGroup) {
        // Check whether the specialtyGroup value is a location or a specialty
        // Default to specialtyGroup just to make the types happy

        const chosenSpecialty = referralDetails?.specialtiesAndLocationTypes!.find(
          s => s.label === formik.values.specialtyGroup
        )
        invariant(chosenSpecialty, 'Chosen specialty not found')
        if (chosenSpecialty.source === SEARCH_TYPES.specialty) field = 'specialtyGroup'
        if (chosenSpecialty.source === SEARCH_TYPES.facility) field = 'locationType'
        payload[field] = chosenSpecialty.label
      }
      getElationTemplate.mutateAsync(payload, {
        onSuccess: (response: ElationReferralTempate) => {
          formik.setFieldValue('referralBody', response.body)
        },
      })
    }
  }

  /**
   * Start Form States
   */
  const isDisabled =
    isLoading ||
    Boolean(steerage.isLocked) ||
    Boolean(steerage.wasElationLetterCreatedFromLucian) ||
    steerage.segment !== null

  /**
   * When this component is not in read-only mode, we need to make sure that deprecated
   * Specialty Groups are not used. Read-only Steerages may still reference them.
   */
  let specialtyGroupsAndLocationTypeOptions = referralDetails?.specialtiesAndLocationTypes ?? []
  if (!isDisabled) {
    specialtyGroupsAndLocationTypeOptions = specialtyGroupsAndLocationTypeOptions.filter(option => {
      if (!useFacilitySearchTab) {
        return !option.isDeprecated
      } else {
        return !option.isDeprecated && option.source === searchTypeTab
      }
    })
  }

  /**
   * This is the list of services based on the selected specialty
   */
  const servicesBasedOnSpecialty = formik.values.specialtyGroup
    ? specialtyGroupsAndLocationTypeOptions.find(s => s.label === formik.values.specialtyGroup)
        ?.services ?? []
    : []

  /**
   * This is the list of clinical focus areas based on the selected specialty
   */
  const clinicalFocusAreasBasedOnSpecialty = formik.values.specialtyGroup
    ? specialtyGroupsAndLocationTypeOptions.find(s => s.label === formik.values.specialtyGroup)
        ?.clinicalFocusAreas ?? []
    : []

  /**
   * Separate "locked" uneditable state from potential loading spinner type state
   * It's appropriate to disable inputs for loading state, but not appropriate to
   * reorganize grid column sizing based on it
   */
  const isLocked = !!steerage.isLocked

  const isSendToElationDisabled =
    !formik.values?.specialtyGroup ||
    !formik.values?.priority ||
    (!formik.values?.typeOfVisit && virtualAppropriateBasedOnSpecialty == true) ||
    !formik.values?.isLastMile ||
    (formik.values.services.length == 0 &&
      servicesBasedOnSpecialty &&
      servicesBasedOnSpecialty.length > 1)

  // For saving referral request section, we would need to have the specialtyGroup, priority and services present
  const isSaveButtonDisabled =
    !formik.values?.specialtyGroup ||
    !formik.values?.priority ||
    (formik.values.services.length == 0 &&
      servicesBasedOnSpecialty &&
      servicesBasedOnSpecialty.length > 1)

  // Render the readonly referral if the body exists *or* it was generated in Elation
  const shouldRenderReferralTemplate =
    Boolean(steerage?.referral?.body) || steerage.isInitiatedFromLucian === false
  // Allow generating a referral template if the referral was *not* generated in Elation
  const shouldRenderGenerateReferralTemplate =
    Boolean(steerage.isInitiatedFromLucian) && steerage.segment === null

  const shouldRenderSaveButton =
    (!steerage.isInitiatedFromLucian || shouldHideReferral || formik.values.isReferralNotActive) &&
    steerage.segment === null

  const shouldRenderSaveAndSendToElationButton =
    Boolean(
      steerage.isInitiatedFromLucian && !shouldHideReferral && !formik.values.isReferralNotActive
    ) && steerage.segment === null

  const isReferralActive = steerage?.referral?.isActive == true

  // Only render the clinical coordination details referral is active
  const renderClinicalCoordination =
    (!formik.values?.isReferralNotActive && !steerage?.segment) ||
    (steerage?.segment && steerage?.referral?.isActive)
  /**
   * End Form States
   */

  /**
   * Collapsible state for the form
   * Keep separate state for collapsing section, but defer to isLocked state so it
   * expands if the steerage is unlocked
   */
  const [collapse, setCollapse] = useState(isLocked)
  const shouldCollapse = !collapse || !isLocked
  let collapseChiplabel: string | null = null
  const sentDate = steerage?.referral?.vendorCreateDate
  if (sentDate) {
    collapseChiplabel = `Sent to Elation on ${format(
      new Date(sentDate),
      'MMM D, YYYY'
    )} at ${format(new Date(sentDate), 'h:mma')}`
  }

  // Based on specialty value, decide when to show or hide the virtual appropriate dropdown
  let specialty = referralDetails?.specialtiesAndLocationTypes!.find(
    s => s.label === formik.values?.specialtyGroup
  )
  virtualAppropriateBasedOnSpecialty = specialty ? specialty?.doesVirtualPartnerExist : false

  return (
    <Box my={1} mx={1}>
      <form
        onSubmit={e => {
          formik.handleSubmit()
          // Prevent the page from refreshing
          e.preventDefault()
        }}
      >
        <Box>
          {steerage?.person && (
            <Grid item xs={12}>
              <PersonPreference personId={steerage.person} showTitle={false} />
            </Grid>
          )}
        </Box>
        <BackgroundBlock>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              {isLocked ? (
                <IconButton edge="start" onClick={() => setCollapse(c => !c)}>
                  {shouldCollapse ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
              ) : null}
              <Typography fontSize={16} fontWeight="500">
                Request
              </Typography>
            </Grid>
          </Box>
          <Collapse in={shouldCollapse}>
            <Grid container columnSpacing={2} rowSpacing={isLocked ? 1 : 3} mb={1}>
              {useFacilitySearchTab ? (
                <Grid item mt={1} xs={12}>
                  <Box alignSelf={'center'}>
                    <ToggleButtonGroup
                      color="primary"
                      value={searchTypeTab}
                      exclusive
                      onChange={(e, nextTab) => {
                        setSearchTypeTab(nextTab)
                        formik.setFieldValue('specialtyGroup', '')
                        formik.setFieldValue('services', [])
                        formik.setFieldValue('clinicalFocusAreas', [])
                      }}
                      size="small"
                      aria-label="Status"
                      sx={{
                        '.MuiToggleButton-root.MuiToggleButtonGroup-grouped': {
                          padding: '7px 20px',
                          textTransform: 'capitalize',
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '.MuiToggleButton-root.Mui-selected': {
                          backgroundColor: '#f5f5f5',
                          color: '#021A39',
                        },
                      }}
                    >
                      <ToggleButton value={SEARCH_TYPES.specialty} disabled={isDisabled}>
                        Provider
                      </ToggleButton>
                      <ToggleButton value={SEARCH_TYPES.facility} disabled={isDisabled}>
                        Service
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <Autocomplete
                  disabled={isDisabled}
                  onInputChange={(_, val) => {
                    if (val === 'FFNB / Urgent Care' || val === 'FFNB / Remote Testing') {
                      formik.handleChange({
                        target: { name: 'isLastMile', value: 'YES' } as any,
                      } as React.ChangeEvent)
                    } else if (val) {
                      formik.handleChange({
                        target: { name: 'isLastMile', value: 'NO' } as any,
                      } as React.ChangeEvent)
                    } else {
                      formik.handleChange({
                        target: { name: 'isLastMile', value: null } as any,
                      } as React.ChangeEvent)
                    }

                    // Only reset services if specialty actually changed
                    if (val !== formik.values.specialtyGroup) {
                      formik.setFieldValue('services', [])
                    }

                    return formik.handleChange({
                      target: { name: 'specialtyGroup', value: val } as any,
                    } as React.ChangeEvent)
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.specialtyGroup}
                  options={specialtyGroupsAndLocationTypeOptions}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      label={searchTypeTab === SEARCH_TYPES.facility ? 'Service Type' : 'Specialty'}
                    />
                  )}
                />
              </Grid>
              {/* Only show the servicesBasedOnSpecialty if the length is greater then one as we will auto select the only service*/}
              {/* If length is 1, just show the service category */}
              {servicesBasedOnSpecialty.length > 1 ? (
                <Grid item xs={6}>
                  <ServiceAutocomplete
                    disabled={isDisabled}
                    services={servicesBasedOnSpecialty}
                    onChangeService={value => {
                      // This onChange handler emits the selected values *by category*
                      // We need to reassemble the list by ID
                      let newServices: Service[] = []
                      value.forEach(v => {
                        const found = servicesBasedOnSpecialty.find(s => s.id === v.id)
                        if (found) newServices.push(found)
                      })
                      return formik.handleChange({
                        target: {
                          name: 'services',
                          value: newServices,
                        } as any,
                      } as React.ChangeEvent)
                    }}
                    selectedServices={formik.values.services}
                  />
                  {formik.errors.services ? (
                    <FormHelperText error>{formik.errors.services}</FormHelperText>
                  ) : null}
                </Grid>
              ) : servicesBasedOnSpecialty.length === 1 && selfServiceEnabled ? (
                <Grid item xs={6}>
                  <TextField
                    disabled={true}
                    fullWidth
                    label="Service"
                    value={servicesBasedOnSpecialty[0].category?.description}
                    variant="standard"
                  />
                </Grid>
              ) : (
                <Grid item xs={6}></Grid>
              )}
              {clinicalFocusAreasBasedOnSpecialty.length > 0 ? (
                <Grid item xs={6}>
                  <ClinicalFocusAreaAutocomplete
                    disabled={isDisabled}
                    clinicalFocusAreas={clinicalFocusAreasBasedOnSpecialty}
                    onChangeClinicalFocusArea={value => {
                      // This onChange handler emits the selected values *by category*
                      // We need to reassemble the list by ID
                      let newClinicalFocusAreas: ClinicalFocusArea[] = []
                      value.forEach(v => {
                        const found = clinicalFocusAreasBasedOnSpecialty.find(s => s.id === v.id)
                        if (found) newClinicalFocusAreas.push(found)
                      })
                      return formik.handleChange({
                        target: {
                          name: 'clinicalFocusAreas',
                          value: newClinicalFocusAreas,
                        } as any,
                      } as React.ChangeEvent)
                    }}
                    selectedClinicalFocusAreas={formik.values.clinicalFocusAreas}
                  />
                  {formik.errors.services ? (
                    <FormHelperText error>{formik.errors.services}</FormHelperText>
                  ) : null}
                </Grid>
              ) : null}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                    Priority
                  </InputLabel>
                  <Select
                    label="Priority"
                    name="priority"
                    value={formik.values.priority}
                    onChange={e => {
                      formik.setFieldValue('priority', e.target.value)
                      formik.setFieldTouched('priority')
                    }}
                    variant="standard"
                    disabled={isDisabled}
                    fullWidth
                    required
                  >
                    {Object.keys(ReferralPriority).map(priorityKey => (
                      <MenuItem key={priorityKey} value={priorityKey}>
                        {ReferralPriority[priorityKey]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled={isDisabled}
                  fullWidth
                  label="Preferred partner or provider"
                  name="preferredProviderOrFacility"
                  onChange={formik.handleChange}
                  value={formik.values.preferredProviderOrFacility}
                  variant="standard"
                />
              </Grid>
            </Grid>
          </Collapse>
        </BackgroundBlock>
        {!shouldHideReferral && (
          <BackgroundBlock>
            <Box sx={{ display: 'flex' }}>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                <Typography fontSize={16} fontWeight="500">
                  Clinical Referral
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                {isReferralActive ? (
                  collapseChiplabel && (
                    <Chip color="success" size="small" label={collapseChiplabel} />
                  )
                ) : steerage?.memberServiceLevel != MemberServiceLevelConfig.CARE ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isReferralNotActive}
                        name="isReferralNotActive"
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                      />
                    }
                    label="Clinical coordination is not needed"
                  />
                ) : null}
              </Grid>
            </Box>

            {renderClinicalCoordination ? (
              <>
                <Grid container columnSpacing={2} rowSpacing={isLocked ? 1 : 3}>
                  <Grid item xs={6} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                        Notify clinician if the member doesn&apos;t schedule
                      </InputLabel>
                      <Select
                        error={!!formik.errors.notifyClinicianYn}
                        disabled={isDisabled}
                        value={formik.values.notifyClinicianYn}
                        name="notifyClinicianYn"
                        onChange={e => {
                          return formik.handleChange({
                            target: { name: 'notifyClinicianYn', value: e.target.value } as any,
                          } as React.ChangeEvent)
                        }}
                        label="Notify clinician if the member doesn't schedule"
                        variant="standard"
                        fullWidth
                        required
                      >
                        <MenuItem value="YES">Yes</MenuItem>
                        <MenuItem value="NO">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {virtualAppropriateBasedOnSpecialty ? (
                    <Grid item xs={6} mt={1}>
                      <FormControl fullWidth>
                        <InputLabel disabled={isDisabled} required sx={{ ml: -1.5 }}>
                          Virtual appropriate
                        </InputLabel>
                        <Select
                          label="Virtual appropriate"
                          name="typeOfVisit"
                          onChange={e => {
                            return formik.handleChange({
                              target: { name: 'typeOfVisit', value: e.target.value } as any,
                            } as React.ChangeEvent)
                          }}
                          value={formik.values.typeOfVisit}
                          variant="standard"
                          disabled={isDisabled}
                          fullWidth
                          required
                        >
                          <MenuItem value="Any">Yes</MenuItem>
                          <MenuItem value="In Person">No</MenuItem>
                          {/**
                           * The following items are deprecated, but necessary in order to render values from legacy data.
                           * If the API-side choices are removed and a backfill is done, these can be removed.
                           */}
                          <MenuItem sx={{ display: 'none' }} disabled value="Virtual">
                            Virtual
                          </MenuItem>
                          <MenuItem sx={{ display: 'none' }} disabled value="In Person Or Virtual">
                            In Person Or Virtual
                          </MenuItem>
                          <MenuItem sx={{ display: 'none' }} disabled value="Home">
                            In Home
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={6}></Grid>
                  )}
                </Grid>
                {shouldRenderReferralTemplate ? (
                  <>
                    <Grid item xs={12} mt={1}>
                      {steerage?.referral?.body?.length ? (
                        <Grid item xs={12} mb={1}>
                          <Typography fontWeight="500">Referral body</Typography>
                          <Typography sx={{ whiteSpace: 'break-spaces' }}>
                            {steerage?.referral?.body}
                          </Typography>
                        </Grid>
                      ) : (
                        <Typography color="GrayText" fontStyle="italic">
                          No referral body entered in Elation
                        </Typography>
                      )}
                    </Grid>
                  </>
                ) : shouldRenderGenerateReferralTemplate ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      ml={-1}
                      mt={1}
                      mb={1}
                      sx={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        name="createReferralTemplate"
                        disabled={isLoading || isSendToElationDisabled}
                        onClick={handleCreateReferralTemplateClick}
                        endIcon={
                          getElationTemplate.isLoading ? (
                            <CircularProgress size="1em" color="inherit" />
                          ) : null
                        }
                      >
                        <Chip
                          icon={<AutoAwesomeIcon />}
                          label={'Generate Referral Template'}
                          variant="outlined"
                          size="small"
                          sx={{
                            color: 'primary.dark',
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                          }}
                          disabled={isLoading || isSendToElationDisabled}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                      <Grid item xs={12} mb={1}>
                        <Typography fontWeight="500">Referral body</Typography>
                      </Grid>
                      <TextField
                        required
                        disabled={isDisabled || getElationTemplate.isLoading}
                        multiline
                        fullWidth
                        label="Referral body"
                        name="referralBody"
                        variant="standard"
                        onChange={formik.handleChange}
                        value={formik.values.referralBody}
                      />
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
          </BackgroundBlock>
        )}
        <Grid item xs={12} mt={2}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {shouldRenderSaveAndSendToElationButton ? (
              <>
                <Button
                  variant="outlined"
                  type="submit"
                  name="sendToElation"
                  onClick={() => {
                    formik.setFieldValue('sendToElation', true)
                    formik.setFieldTouched('sendToElation')
                  }}
                  disabled={isLoading || isSendToElationDisabled}
                  endIcon={
                    editSteerage.isLoading ? <CircularProgress size="1em" color="inherit" /> : null
                  }
                >
                  Save and Send to Elation
                </Button>
              </>
            ) : null}
            {shouldRenderSaveButton ? (
              <>
                <Button
                  variant="outlined"
                  type="submit"
                  name="saveReferral"
                  disabled={isLoading || isSaveButtonDisabled}
                  endIcon={
                    editSteerage.isLoading ? <CircularProgress size="1em" color="inherit" /> : null
                  }
                >
                  Save
                </Button>
              </>
            ) : null}
          </Box>
        </Grid>
      </form>
    </Box>
  )
}

export const BackgroundBlock: React.FC<BoxProps> = props => {
  const { children, ...rest } = props
  return (
    <Box
      {...rest}
      sx={{
        mx: -2,
        my: 2,
        p: 2,
        borderRadius: 2,
        border: '1px solid rgba(221, 221, 221, 1)',
      }}
    >
      {children}
    </Box>
  )
}
