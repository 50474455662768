import type { FC } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FFInput from '~/components/Generic/FFInput'
import { useState, useEffect } from 'react'
import { Shift } from './utils'

export interface ShiftEditorProps {
  shift: Shift
  displayError: boolean
  isHistory: boolean
  onChange: (shift: Shift) => any
  onDelete: (shift: Shift) => any
  onError: (error: boolean) => any
}

const ShiftEditor: FC<ShiftEditorProps> = props => {
  const { shift, displayError } = props
  const [timeErrorText, setTimeErrorText] = useState<string>('')
  const [dateErrorText, setDateErrorText] = useState<string>('')
  const [lowerEmptyErrorText, setLowerEmptyErrorText] = useState<string>('')
  const [startEmptyErrorText, setStartEmptyErrorText] = useState<string>('')
  const [stopEmptyErrorText, setStopEmptyErrorText] = useState<string>('')

  useEffect(() => {
    let error = false
    if (!shift.effectivePeriod.lower) {
      if (displayError) setLowerEmptyErrorText('Start empty')
      error = true
    } else setLowerEmptyErrorText('')
    if (!shift.startTime) {
      if (displayError) setStartEmptyErrorText('From empty')
      error = true
    } else setStartEmptyErrorText('')
    if (!shift.stopTime) {
      if (displayError) setStopEmptyErrorText('To empty')
      error = true
    } else setStopEmptyErrorText('')
    if (shift.startTime > shift.stopTime) {
      if (displayError) setTimeErrorText('From more than To')
      error = true
    } else setTimeErrorText('')
    if (shift.effectivePeriod.upper && shift.effectivePeriod.lower > shift.effectivePeriod.upper) {
      if (displayError) setDateErrorText('Start more than End')
      error = true
    } else setDateErrorText('')
    props.onError(error)
  }, [shift, displayError])

  const isShiftOnStartOrEndTimeInPast = (shiftStartOn: string, shiftStartOrEndTime: string) => {
    const shiftStartOnStartTime = new Date(shiftStartOn + ':' + shiftStartOrEndTime)
    const currentDateTime = new Date()
    return shiftStartOnStartTime < currentDateTime
  }

  const getMinValue = () => {
    const dtInEst = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
    const estDt = new Date(dtInEst)
    const shiftStartTime = shift.startTime.split(':')

    if (
      estDt <
      new Date(
        estDt.getFullYear(),
        estDt.getMonth(),
        estDt.getDate(),
        Number(shiftStartTime[0]),
        Number(shiftStartTime[1])
      )
    )
      return new Date(estDt.setDate(estDt.getDate() - 1)).toISOString().slice(0, 10)
    else return new Date().toISOString().slice(0, 10)
  }

  return (
    <Box marginTop={3}>
      <Box display="flex" alignItems="flex-start">
        <Box marginRight={1}>
          <FFInput
            error={!!timeErrorText || !!startEmptyErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shift,
                startTime: event.target.value,
              })
            }
            value={shift.startTime}
            label="From"
            type="time"
            InputLabelProps={{ shrink: true }}
            helperText={startEmptyErrorText || timeErrorText}
            InputProps={{
              inputProps: {
                readOnly:
                  props.isHistory ||
                  isShiftOnStartOrEndTimeInPast(shift.effectivePeriod.lower, shift.startTime)
                    ? true
                    : false,
              },
            }}
          />
        </Box>
        <Box alignSelf="center">–</Box>
        <Box marginLeft={1}>
          <FFInput
            error={!!timeErrorText || !!stopEmptyErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shift,
                stopTime: event.target.value,
              })
            }
            value={shift.stopTime}
            label="To"
            type="time"
            InputLabelProps={{ shrink: true }}
            helperText={stopEmptyErrorText || timeErrorText}
            InputProps={{
              inputProps: {
                readOnly:
                  props.isHistory ||
                  isShiftOnStartOrEndTimeInPast(shift.effectivePeriod.lower, shift.stopTime)
                    ? true
                    : false,
              },
            }}
          />
        </Box>
        <Box marginLeft={4}>
          <FFInput
            error={!!dateErrorText || !!lowerEmptyErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shift,
                effectivePeriod: {
                  lower: event.target.value,
                  upper: shift.effectivePeriod.upper,
                  bounds: shift.effectivePeriod.bounds,
                },
              })
            }
            value={shift.effectivePeriod.lower}
            label="Start On"
            type="date"
            InputLabelProps={{ shrink: true }}
            helperText={lowerEmptyErrorText || dateErrorText}
            InputProps={{
              inputProps: {
                min: new Date().toISOString().slice(0, 10),
                readOnly:
                  props.isHistory ||
                  isShiftOnStartOrEndTimeInPast(shift.effectivePeriod.lower, shift.stopTime)
                    ? true
                    : false,
              },
            }}
          />
        </Box>
        <Box marginLeft={2}>
          <FFInput
            error={!!dateErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shift,
                effectivePeriod: {
                  lower: shift.effectivePeriod.lower,
                  upper: event.target.value,
                  bounds: shift.effectivePeriod.bounds,
                },
              })
            }
            value={shift.effectivePeriod.upper}
            label="End On"
            type="date"
            InputLabelProps={{ shrink: true }}
            helperText={dateErrorText}
            InputProps={{
              inputProps: {
                min: getMinValue(),
                readOnly: props.isHistory ? true : false,
              },
            }}
          />
        </Box>
        <Typography>{shift.isEdited}</Typography>
        <Box
          display={
            props.isHistory ||
            isShiftOnStartOrEndTimeInPast(shift.effectivePeriod.lower, shift.startTime)
              ? 'none'
              : 'block'
          }
        >
          <IconButton
            onClick={() => props.onDelete(shift)}
            sx={theme => ({
              '&:hover': {
                backgroundColor: 'transparent',
              },
              borderRadius: 'unset',
            })}
          >
            <DeleteIcon color="inherit" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default ShiftEditor
