import { Props as CaseEditorProps } from '../Cases/CaseEditor'
import { useSelector } from 'react-redux'
import { uniqBy } from 'lodash/fp'
import { ICaseRelation } from '~/models/Case'
import { IChatMessage, Message } from './Chat'
import { IPhoneCall } from '~/models/PhoneCall'
import { useCases } from '~/api/CaseService'
import { getDefaultPatientThread } from '~/utils/chat'
import { useDispatch } from 'react-redux'
import { loadThreadHistory } from '~/redux/slices/chat/thunks'
import Moment from 'moment'
import { IEmail } from '~/models/Email'

export const findMessageIdentifier = message => {
  // For real-time chat messages which haven't been loaded from the backend
  // we only have a UID, not an ID
  // Real-time message won't have any associated Case data yet anyways
  let messageIdentifier: CaseEditorProps['messageIdentifier']
  if (message.id) {
    messageIdentifier = {
      id: message.id,
      type: message.kind === 'phonecall' ? 'phonecall' : 'chatmessagev2',
    }
  } else {
    messageIdentifier = {
      id: message.uid!,
      type: 'chatmessagev2-uid',
    }
  }
  return messageIdentifier
}

export const instanceOfPhoneCall = (message: IChatMessage | IPhoneCall): message is IPhoneCall => {
  return 'createdBy' in message
}

export const instanceOfEmail = (message: IChatMessage | IPhoneCall | IEmail): message is IEmail => {
  return 'subject' in message
}

export interface MessageIdToCaseInfoDict {
  [messageId: number]: { caseId: number; categoryId: number }[]
}

export const messageType = (message: Message) =>
  instanceOfEmail(message) ? 'email' : instanceOfPhoneCall(message) ? 'phonecall' : 'chatmessagev2'

export const useMessageCaseInfo = (patientId, personId) => {
  // The total set of messages we're working with at a time is the set of
  // 1) the default thread
  // 2) any historical messages
  const patientThread = useSelector(state => state.chat.patientThreads?.[patientId]?.messages) || []
  const historicalMessages = useSelector(state => state.chat.historicalMessages) || []
  const allMessages = uniqBy('uid', patientThread.concat(historicalMessages))
  // For all messages, find the Case IDs they're linked to
  const { data: patientCases } = useCases({
    personId: personId,
  })

  const messageIsLinkedToCaseRelation = (message: IChatMessage, caseRelation: ICaseRelation) => {
    // First, find an identifier for the message
    // For real-time chat messages which haven't been loaded from the backend
    // we only have a UID, not an ID
    // But, we also know that real-time message won't have any associated Case data anyways
    const id = message.id ? message.id : (message as IChatMessage).uid
    const type = messageType(message)
    return caseRelation.objectId === id && caseRelation.contentType === type
  }

  const messageIdToCaseInfo: MessageIdToCaseInfoDict = {}

  for (const caseObj of patientCases || []) {
    const relations = caseObj.relations
    for (const relation of relations) {
      for (const message of allMessages) {
        if (messageIsLinkedToCaseRelation(message, relation)) {
          const newVal = [...(messageIdToCaseInfo[message.id] || [])]
          newVal.push({ caseId: caseObj.id, categoryId: caseObj.category })
          messageIdToCaseInfo[message.id] = newVal
        }
      }
    }
  }

  return { messageIdToCaseInfo, patientCases }
}

export const useRefreshDefaultThread = patient => {
  const dispatch = useDispatch()
  const defaultThread = getDefaultPatientThread(patient.id)
  return () => dispatch(loadThreadHistory(defaultThread, patient.person.id))
}

export const messageDateInfo = (message: Message) => {
  // Format timestamp
  const date = instanceOfEmail(message)
    ? Moment(new Date(message.createdAt))
    : instanceOfPhoneCall(message)
    ? Moment(new Date(message.calledAt))
    : Moment(new Date(message.sentAt || message.timetoken / 1e4))

  const messageDate = date.format('ddd. MMM. D, YYYY [at] h:mm a')
  // Add a hover title for specific date
  const preciseDate = date.format('dddd, MMMM Do YYYY, h:mm:ss a')
  return { preciseDate, messageDate }
}

export const chatMessageText = (message: Message, sender): string => {
  if (!instanceOfPhoneCall(message)) {
    if (message.event) {
      let clinicianLabel = `${sender.firstName} ${sender.lastName}`
      const title = sender.providerFields.title
      const role = sender.providerFields?.internalRole
      if (title) clinicianLabel += `, ${title}`
      if (!title && !!role) clinicianLabel += `, ${role},`
      return `${clinicianLabel} has temporarily joined this chat.`
    } else {
      return message.text || ''
    }
  }

  return ''
}
