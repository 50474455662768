import { IChatMessage, IEmailMessage } from './Chat'
import { Component } from 'react'
import { PatientUser } from '~/legacy/core'
import { sortBy } from 'lodash'

import { ICaseRelation } from '~/models/Case'
import PhoneCallMessage from '../PhoneCall/PhoneCallMessage'
import { SelectedChatItem } from '~/models/Communication'
import { parse } from 'date-fns'
import qs from 'query-string'
import { useSelector } from 'react-redux'
import { ClassNameMap } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { IPhoneCallMessage } from './Chat'
import { useCommunications } from '~/api/CommunicationService'
import EmailChatMessage from '../Email/EmailChatMessage'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'

const useStyles = makeStyles()(theme => {
  return {
    noMoreMessages: {
      textAlign: 'center' as const,
      fontSize: '1.2rem',
    },
  }
})

interface IChatMessagesProps {
  messages: IChatMessage[]
  patient: PatientUser
  selectedMessage: SelectedChatItem | null
  linkedMessages: ICaseRelation[]
}

const ChatMessages = (props: IChatMessagesProps) => {
  const isCaseEmailEnabled = useFeatureSwitch('cases.email')?.active
  const { patient, linkedMessages } = props
  const providers = useSelector(state => state.providers)
  const { data: communicationList } = useCommunications({ personId: patient.person.id })
  const { classes } = useStyles()
  const messages: IPhoneCallMessage[] | IEmailMessage[] = []
  if (communicationList) {
    for (const message of communicationList) {
      if (message.contentType === 'phonecall') {
        messages.push({
          ...message.contentObject,
          kind: 'phonecall',
          sentAt: message.contentObject.calledAt,
        })
      }
      if (message.contentType === 'email') {
        messages.push({
          ...message.contentObject,
          kind: 'email',
          sentAt: message?.contentObject?.emailSentAt
            ? message?.contentObject?.emailSentAt
            : message.contentObject.createdAt,
        })
      }
    }
  }

  // Sort all messages (chat and phone calls) by their sentAt timestamp. Parse the timestamp first;
  // even though they should be in RFC 3339 format, different time zone offsets make the
  // lexicographic order not necessarily match the chronological order.
  const allMessages: (IPhoneCallMessage | IEmailMessage)[] = sortBy([...messages], m =>
    parse(m.sentAt)
  )
  const noCommunicationMessage = isCaseEmailEnabled
    ? 'No phone / email conversations'
    : 'No phone conversations'

  let messageComponents: JSX.Element[] = []
  if (allMessages.length > 0) {
    messageComponents = messageComponents.concat(
      allMessages.map((message, i) => {
        const isLastMessage = allMessages.length - 1 == i
        let higlightChatMessageContent = false
        const highlightPhoneCall =
          linkedMessages && linkedMessages.length > 0
            ? linkedMessages.find(
                cr => cr.objectId === message.id && cr.contentType === 'phonecall'
              )
            : undefined
        if (message.kind === 'phonecall') {
          higlightChatMessageContent = highlightPhoneCall !== undefined
          return (
            <PhoneCallMessage
              key={`phonecall-${message.id}`}
              isLastMessage={isLastMessage}
              phoneCall={message}
              patient={patient}
              phoneCallCases={message.cases || []}
              higlightChatMessageContent={higlightChatMessageContent}
              patientReadAt={null}
              providers={providers}
              isSelectingMultiple={false}
            />
          )
        }
        if (message.kind === 'email') {
          higlightChatMessageContent = highlightPhoneCall !== undefined
          return (
            <EmailChatMessage
              key={`email-${message.id}`}
              email={message}
              emailCases={message.cases || []}
            />
          )
        }

        return <></>
      })
    )
  } else {
    messageComponents[0] = (
      <div className={classes.noMoreMessages}>{noCommunicationMessage}</div>
    ) as JSX.Element
  }
  return <>{messageComponents}</>
}

export interface IChatProps {
  patient: any
  location: any
  selectedMessage: SelectedChatItem | null
  linkedMessages: ICaseRelation[]
  classes: ClassNameMap<string>
}

export class CommunicationList extends Component<IChatProps> {
  messagesScroller: Element | null = null
  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { selectedMessage } = this.props

    if (selectedMessage) {
      this.scrollChatThread()
    }

    this.setState({ loaded: true })
    this.scrollToBottom()
  }

  scrollChatThread = async () => {
    const { selectedMessage } = this.props
    if (selectedMessage?.contentType === 'phonecall') {
      this.scrollToMessage(selectedMessage)
      return
    }
  }

  setMessageScrollerRef = element => {
    this.messagesScroller = element
    if (element !== null) {
      const scrollToMessageId = qs.parse(this.props.location.search).scrollToMessageId
      if (scrollToMessageId) {
        this.scrollToMessage({ contentType: 'chatmessagev2', id: Number(scrollToMessageId) })
      } else {
        this.scrollToBottom()
      }
    }
  }

  scrollToMessage = (selectedMessage: SelectedChatItem) => {
    if (!this.messagesScroller) return
    const messageEl = this.messagesScroller.querySelector(
      `[data-id="${selectedMessage.contentType === 'phonecall' ? 'phonecall-' : ''}${
        selectedMessage.id
      }"]`
    )
    if (!messageEl) return
    const offset = (messageEl as any).offsetTop
    this.messagesScroller!.scrollTop = offset
    this.setState({ scrollToMessage: selectedMessage })
  }

  scrollToBottom = () => {
    // After appending messages, scroll down to the bottom
    // Get the height of the inner containing div
    if (!this.messagesScroller) return
    try {
      const height = (this.messagesScroller.firstChild as any).clientHeight
      this.messagesScroller.scrollTop = height
    } catch (e) {
      // continue regardless of error
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div ref={this.setMessageScrollerRef} className={`${classes.messages}`}>
          <div className={classes.messagesInner}>
            <ChatMessages {...this.props} messages={[]} />
          </div>
        </div>
      </div>
    )
  }
}

export default CommunicationList
