import { useState, type FC } from 'react'
import { Chip, Grid, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import { apiClient } from '~/api/rest'
import { usePromise } from '~/utils/usePromise'
import EditField from '../../Generic/EditField'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import { ValueSetter } from '~/types'
import { verifyMember } from '~/components/PatientDetail/EditPatient/utils'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { logger } from '~/utils/logger'

interface EditPersonFieldsProps {
  firstName: string
  lastName: string
  dob: string
  preferredName: string
  employer: number | null
  gender: string[] | null
  sex: string
  pronouns: string | null
  loading: boolean
  isUser: boolean
  isNewMember?: boolean
  patientId?: number | null
  memberIdentifierType?: string | null
  setValues: ValueSetter
  setFieldValue: (name: string, value: any) => any
  setFieldTouched: (name: string) => any
}

const GENDERS = ['Unknown', 'Female', 'Male']

export const EditPersonFields: FC<EditPersonFieldsProps> = props => {
  const [verificationCode, setVerificationCode] = useState('')
  const dispatch = useDispatch()
  const init = usePromise(
    async () => {
      const [employers, pronouns, genders] = await Promise.all([
        apiClient.rest.get('/insurance/employer/'),
        apiClient.rest.get('/facts/pronouns/?is_deleted=false'),
        apiClient.rest.get('/facts/gender-identities/?is_deleted=false'),
      ])
      return {
        employers: employers.map(e => ({ name: e.name, id: e.id })),
        pronouns: pronouns.map(p => p.label),
        genders: genders.map(g => g.label),
      }
    },
    { error: 'Failed to fetch form option data' }
  )
  const featureAccess = useFeatureAccess()?.tenant
  const {
    firstName,
    lastName,
    dob,
    preferredName,
    employer,
    sex,
    gender,
    pronouns,
    isUser,
    isNewMember,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = props

  function getFieldName(name: string) {
    if (isUser == true) name = 'person.' + name
    return name
  }

  const handleMemberVerification = async () => {
    const payload = { person_id: props.patientId, member_identification_number: verificationCode }
    const response = await verifyMember(payload)
    if (response.isVerified) {
      dispatch(queueNotification({ variant: 'success', message: 'SSN or Employee ID is verified' }))
      logger.info(
        'SSN or Employee ID is verified for person',
        props.patientId,
        'as',
        verificationCode
      )
    } else {
      dispatch(
        queueNotification({ variant: 'error', message: 'SSN or Employee ID is not verified' })
      )
      logger.info(
        'SSN or Employee ID verification failed for person',
        props.patientId,
        'with value',
        verificationCode
      )
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <EditField
          {...setValues('firstName', firstName)}
          label="First Name"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues('lastName', lastName)}
          label="Last Name"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues(getFieldName('preferredName'), preferredName)}
          label="Preferred Name"
        />
        <EditField
          {...setValues(getFieldName('dob'), dob)}
          InputLabelProps={{ shrink: true }}
          label="Date of Birth"
          type="date"
          required={featureAccess?.personMatchingDuringSignup}
        />
        <EditField
          {...setValues(getFieldName('sex'), sex)}
          label="Sex Assigned at Birth"
          select
          SelectProps={{ native: true }}
          required={featureAccess?.personMatchingDuringSignup}
        >
          {GENDERS.map(v => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </EditField>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <EditField
              {...setValues(getFieldName('employer'), employer)}
              label="Employer"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              disabled={isNewMember}
            >
              <option value="">(none)</option>
              {init.result?.employers.map(employer => (
                <option key={employer.id} value={employer.id}>
                  {employer.name}
                </option>
              ))}
            </EditField>

            <EditField
              onChange={e => {
                setVerificationCode(e.target.value)
              }}
              label={
                props.memberIdentifierType
                  ? props.memberIdentifierType == 'SSN'
                    ? 'Last 4 of SSN'
                    : 'Last 4 of Employee ID'
                  : ''
              }
              onClick={handleMemberVerification}
              variant="standard"
              showChangeButton={true}
              changeButtonLabel={'Verify'}
              value={verificationCode}
              changeButtonSize={'small'}
              changeButtonDisabled={props.memberIdentifierType ? false : true}
              InputProps={props.memberIdentifierType ? { readOnly: false } : { readOnly: true }}
            ></EditField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            // Only one value will be returned from the backend for the gender field.
            // Right now in DB the gender field is a array of varchar[],
            // once it is migrated to varchar the same will be updated here.
            value={gender && gender.length > 0 ? gender[0] : null}
            onChange={(_, val) => {
              setFieldValue(getFieldName('gender'), [val])
              setFieldTouched(getFieldName('gender'))
            }}
            options={init.result?.genders || []}
            disabled={props.loading}
            // @ts-ignore Unclear why this isn't lining up
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip size="small" label={option} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={params => <TextField {...params} label="Gender Identity" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            onInputChange={(_, val) => {
              setFieldValue(getFieldName('pronouns'), val)
              setFieldTouched(getFieldName('pronouns'))
            }}
            value={pronouns ?? ''}
            disabled={props.loading}
            options={init.result?.pronouns || []}
            freeSolo
            renderInput={params => <TextField {...params} label="Preferred Pronouns" />}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default EditPersonFields
