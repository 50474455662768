import type { FC } from 'react'
import { Autocomplete, Box, Checkbox, IconButton, TextField, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FFInput from '~/components/Generic/FFInput'
import { useState, useEffect } from 'react'
import { ShiftException } from './utils'
import moment from 'moment'

export interface ShiftExceptionEditorProps {
  shiftException: ShiftException
  displayError: boolean
  isHistory: boolean
  onChange: (shiftException: ShiftException) => any
  onDelete: (shiftException: ShiftException) => any
  onError: (error: boolean) => any
}

const EXCEPTION_REASONS = [
  'Late Call out',
  'PTO',
  'Late PTO',
  'Meeting',
  'Offsite Business',
  'Other',
]

const parseDateTime = (dateTimeString: string) =>
  moment(dateTimeString).tz('America/New_York').format('YYYY-MM-DDTHH:mm')

const formatDateTime = (dateTimeString: string) =>
  moment.tz(dateTimeString, 'America/New_York').tz('UTC').format()

const parseDate = (dateTimeString: string) =>
  moment(dateTimeString).tz('America/New_York').format('YYYY-MM-DD')

const formatDate = (dateTimeString: string, isStart: boolean) =>
  moment
    .tz(dateTimeString + (isStart ? 'T00:00' : 'T23:59'), 'America/New_York')
    .tz('UTC')
    .format()

const ShiftExceptionEditor: FC<ShiftExceptionEditorProps> = props => {
  const { shiftException, displayError } = props
  const [dateErrorText, setDateErrorText] = useState<string>('')
  const [lowerEmptyErrorText, setLowerEmptyErrorText] = useState<string>('')
  const [upperEmptyErrorText, setUpperEmptyErrorText] = useState<string>('')

  useEffect(() => {
    let error = false
    if (!shiftException.period.lower) {
      if (displayError) setLowerEmptyErrorText('Start empty')
      error = true
    } else setLowerEmptyErrorText('')
    if (!shiftException.period.upper) {
      if (displayError) setUpperEmptyErrorText('End empty')
      error = true
    } else setUpperEmptyErrorText('')
    if (shiftException.period.lower > shiftException.period.upper) {
      if (displayError) setDateErrorText('Start more than End')
      error = true
    } else setDateErrorText('')
    props.onError(error)
  }, [shiftException, displayError])

  const isExceptionInPast = dateOrAndTime => {
    dateOrAndTime = new Date(dateOrAndTime)
    const currentDateTime = new Date()
    return dateOrAndTime < currentDateTime
  }

  return (
    <Box marginTop={3}>
      <Box display="flex" alignItems="flex-start">
        <Box>
          <FFInput
            required
            error={!!dateErrorText || !!lowerEmptyErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shiftException,
                period: {
                  ...shiftException.period,
                  lower: shiftException.isAllDay
                    ? formatDate(event.target.value, true)
                    : formatDateTime(event.target.value),
                },
              })
            }
            value={
              shiftException.isAllDay
                ? parseDate(shiftException.period.lower)
                : parseDateTime(shiftException.period.lower)
            }
            label={shiftException.isAllDay ? 'Starting' : 'Start On'}
            type={shiftException.isAllDay ? 'date' : 'datetime-local'}
            InputLabelProps={{ shrink: true }}
            helperText={lowerEmptyErrorText || dateErrorText}
            InputProps={{
              inputProps: {
                min: shiftException.isAllDay
                  ? new Date().toISOString().slice(0, 10)
                  : new Date().toISOString().slice(0, 16),
                readOnly:
                  props.isHistory ||
                  isExceptionInPast(
                    shiftException.isAllDay
                      ? parseDate(shiftException.period.lower)
                      : parseDateTime(shiftException.period.lower)
                  )
                    ? true
                    : false,
              },
            }}
          />
        </Box>
        <Box marginLeft={2}>
          <FFInput
            required
            error={!!dateErrorText || !!upperEmptyErrorText}
            variant="outlined"
            onChange={event =>
              props.onChange({
                ...shiftException,
                period: {
                  ...shiftException.period,
                  upper: shiftException.isAllDay
                    ? formatDate(event.target.value, false)
                    : formatDateTime(event.target.value),
                },
              })
            }
            value={
              shiftException.isAllDay
                ? parseDate(shiftException.period.upper)
                : parseDateTime(shiftException.period.upper)
            }
            label={shiftException.isAllDay ? 'Through' : 'End On'}
            type={shiftException.isAllDay ? 'date' : 'datetime-local'}
            InputLabelProps={{ shrink: true }}
            helperText={upperEmptyErrorText || dateErrorText}
            InputProps={{
              inputProps: {
                min: shiftException.isAllDay
                  ? new Date().toISOString().slice(0, 10)
                  : new Date().toISOString().slice(0, 16),
                readOnly: props.isHistory ? true : false,
              },
            }}
          />
        </Box>
        <Box marginLeft={2}>
          <Autocomplete<string>
            size="small"
            options={EXCEPTION_REASONS.sort()}
            value={shiftException.reason}
            renderInput={params => <TextField {...params} label="Reason" />}
            onChange={(_, selected) => {
              props.onChange({
                ...shiftException,
                reason: selected,
              })
            }}
            style={{ width: 280 }}
            readOnly={props.isHistory ? true : false}
          />
        </Box>
        <Box
          display={
            props.isHistory ||
            isExceptionInPast(
              shiftException.isAllDay
                ? parseDate(shiftException.period.lower)
                : parseDateTime(shiftException.period.lower)
            )
              ? 'none'
              : 'block'
          }
        >
          <IconButton
            onClick={() => props.onDelete(shiftException)}
            sx={theme => ({
              '&:hover': {
                backgroundColor: 'transparent',
              },
              borderRadius: 'unset',
            })}
          >
            <DeleteIcon color="inherit" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          disabled={props.isHistory ? true : false}
          checked={shiftException.isAllDay || false}
          onChange={event => {
            props.onChange({
              ...shiftException,
              isAllDay: event.target.checked,
            })
          }}
        />
        <Typography>All Day</Typography>
      </Box>
    </Box>
  )
}

export default ShiftExceptionEditor
