import { Box, Paper, Step, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material'
import { CheckCircle, FiberManualRecord } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import PrimaryCareProgramMenu from './PrimarycareProgramMenu/PrimaryCareProgramMenu'
import Moment from 'moment'
import { useEffect, useState } from 'react'
import { IProgramEnrollment, StatusEventDateMap } from '~/models/ProgramEnrollment'
import {
  BENEFIT_PROGRAM_STATUSES,
  BENEFIT_PROGRAM_UID,
  DISCHARGE_IN_PROGRESS_TEXT,
  NO_ENROLLMENT_TEXT,
  PRIMARYCARE_PROGRAM_STATUSES,
  PRIMARY_CARE_PROGRAM_UID,
} from '~/constants/programEnrollment'

export const readablePrimaryProgramStatus = (status: string): string =>
  PRIMARYCARE_PROGRAM_STATUSES[status] || status

export const readableBenefitProgramStatus = (status: string): string =>
  BENEFIT_PROGRAM_STATUSES[status] || status

const ProgramStatusStepper = ({ program, enrollments, patient }) => {
  const {
    classes: { paper },
  } = useStyles()
  const [statusEventDateMap, setStatusEventDateMap] = useState<StatusEventDateMap[]>([])
  const [activeStep, setActiveStep] = useState<number | undefined>(undefined)
  const [enrollmentStatus, setEnrollmentStatus] = useState<string | undefined>(undefined)
  const [churnedReason, setChurnedReason] = useState<string | undefined>(undefined)

  useEffect(() => {
    const statusMap: StatusEventDateMap[] = []
    let currentActiveStep = 0
    let lastEnrollment = {
      status: '',
      eventDate: null,
      reason: null,
    }
    if (enrollments && enrollments.length > 0) {
      for (const [index, enrollment] of enrollments.entries()) {
        let reason = undefined
        setChurnedReason(reason)
        if (enrollment.reason) {
          reason =
            enrollment.reason.includes(DISCHARGE_IN_PROGRESS_TEXT) &&
            enrollment.reason.split(':').length === 2
              ? enrollment.reason.split(':')[1]
              : enrollment.reason
          setChurnedReason(reason)
        }
        let readableStatus = ''
        if (program.uid == PRIMARY_CARE_PROGRAM_UID) {
          readableStatus = readablePrimaryProgramStatus(enrollment.status)
        } else if (program.uid == BENEFIT_PROGRAM_UID) {
          readableStatus = readableBenefitProgramStatus(enrollment.status)
        }
        statusMap.push({
          status: readableStatus,
          eventDate: getEnrollmentEventDate(enrollment.status, enrollment),
          reason: reason,
        })
        if (index === enrollments.length - 1) {
          lastEnrollment = enrollment
          setEnrollmentStatus(enrollment.status)
          currentActiveStep = index
        } else {
          currentActiveStep = currentActiveStep + enrollments.length - 1
        }
      }
      setActiveStep(currentActiveStep)
      setStatusEventDateMap(statusMap)
      if (program.uid == PRIMARY_CARE_PROGRAM_UID) {
        if (
          lastEnrollment &&
          lastEnrollment?.status == PRIMARYCARE_PROGRAM_STATUSES['Not Established']
        ) {
          statusMap.push({
            status: PRIMARYCARE_PROGRAM_STATUSES.Established,
            eventDate: null,
            reason: '',
          })
          statusMap.push({
            status: PRIMARYCARE_PROGRAM_STATUSES.Churned,
            eventDate: null,
            reason: '',
          })
        }
        if (lastEnrollment && lastEnrollment?.status == PRIMARYCARE_PROGRAM_STATUSES.Established) {
          statusMap.push({
            status: PRIMARYCARE_PROGRAM_STATUSES.Churned,
            eventDate: null,
            reason: '',
          })
        }
      }
      if (program.uid == BENEFIT_PROGRAM_UID) {
        if (lastEnrollment && lastEnrollment?.status == BENEFIT_PROGRAM_STATUSES.Elected) {
          statusMap.push({
            status: BENEFIT_PROGRAM_STATUSES.Enrolled,
            eventDate: null,
            reason: '',
          })
          statusMap.push({
            status: BENEFIT_PROGRAM_STATUSES.Terminated,
            eventDate: null,
            reason: '',
          })
        }
        if (lastEnrollment && lastEnrollment?.status == BENEFIT_PROGRAM_STATUSES.Enrolled) {
          statusMap.push({
            status: BENEFIT_PROGRAM_STATUSES.Terminated,
            eventDate: null,
            reason: '',
          })
        }
      }
    }
  }, [enrollments])

  const getEnrollmentEventDate = (status: string, enrollment: IProgramEnrollment) => {
    const dateFormat = 'MM/DD/YYYY, HH:MM A'
    const period = enrollment.period
    if (status === PRIMARYCARE_PROGRAM_STATUSES['Established'] && enrollment.establishedDate) {
      return Moment(enrollment.establishedDate).format(dateFormat)
    } else if (period.lower) {
      return Moment(period.lower).format(dateFormat)
    }
    return null
  }

  const StepIcon = (props: StepIconProps) => {
    const icons: { [index: string]: React.ReactElement } = {
      completed: (
        <Box color="primary.main">
          <CheckCircle color="inherit" />
        </Box>
      ),
      waiting: (
        <Box color="secondary.main">
          <FiberManualRecord color="inherit" />
        </Box>
      ),
    }
    return <div>{Number(props.icon) - 1 === activeStep ? icons.completed : icons.waiting}</div>
  }

  return (
    <Paper elevation={2} className={paper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
        <Box px={2} py={1} flex={1} mt={2} mb={2}>
          {statusEventDateMap.length > 0 && (
            <Stepper orientation="vertical">
              {statusEventDateMap.map(statusEventDate => (
                <Step key={statusEventDate.status}>
                  <StepLabel StepIconComponent={StepIcon}>
                    <Box color="grey.600" display="flex">
                      {statusEventDate.status}
                      {statusEventDate.eventDate ? ', ' + statusEventDate.eventDate : null}
                    </Box>
                    <Box>{statusEventDate.reason}</Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          {statusEventDateMap.length === 0 && (
            <Typography variant="body1">{NO_ENROLLMENT_TEXT[program.uid]}</Typography>
          )}
        </Box>
        {program.uid === PRIMARY_CARE_PROGRAM_UID && (
          <PrimaryCareProgramMenu
            enrollmentStatus={enrollmentStatus}
            churnedReason={churnedReason}
            patient={patient}
          />
        )}
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    paper: { backgroundColor: theme.palette.grey['A50'], width: '60%' },
  }
})

export default ProgramStatusStepper
