import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import {
  Box,
  IconButton,
  Step,
  StepContent,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material'
import { Cancel, CheckCircle, ExpandLess, ExpandMore, FiberManualRecord } from '@mui/icons-material'
import CheckBox from '~/components/CheckBox/CheckBox'
import Moment from 'moment'
import { apiClient } from '~/api/rest'
import { STATUS_TO_REQUIREMENTS } from '~/components/Onboarding/utils'
import Autocomplete from '@mui/material/Autocomplete'
import { ONBOARDING_STATES } from '~/constants/onboardingStates'

interface OnboardingStatueStepperProps {
  steps: string[]
  indexToKey: string[]
  data: any
  statusTimeStamps: string[]
  selectedStep: number
  setCurrentOnboardingState: (any) => void
}

// TODO: Add typings for these resources.
const onboardingResource = apiClient.resource<any>('/onboarding/')

const OnboardingStatusStepper: FC<OnboardingStatueStepperProps> = (
  props: OnboardingStatueStepperProps
) => {
  const { data, steps, indexToKey } = props
  const { classes } = useStyles()
  const usersById = useSelector<any, any>(state => state.users.byId)
  const user = useSelector<any, any>(state => state.me)
  const [activeStep, setActiveStep] = useState(props.selectedStep)

  useEffect(() => {
    setActiveStep(props.selectedStep)
  }, [props.selectedStep])

  const handleExpand = index => {
    // If expand less is clicked, shrink all steps
    if (activeStep === index) {
      index = -1
    }
    setActiveStep(index)
  }

  const getRequirementsFromIndex = (index: number) => {
    return STATUS_TO_REQUIREMENTS[indexToKey[index]]
  }

  // isStatus refers to status vs. requirements under each status
  const getStatusTimeStampUpdatedBy = (preKey: string, isStatus: boolean) => {
    if (isStatus) {
      return (
        <Typography variant="caption" color="inherit">
          {!(preKey === 'signedup' || data[`${preKey}UpdatedBy`] == null)
            ? `By ${usersById[data[`${preKey}UpdatedBy`]]?.firstName} on `
            : 'On '}
          {Moment(data[`${preKey}At`]).format('LL')} at {Moment(data[`${preKey}At`]).format('LT')}
        </Typography>
      )
    }
    return (
      <Typography color="inherit" variant="caption">
        Completed by{' '}
        {data.patient.id === data[`${preKey}UpdatedBy`]
          ? 'Patient'
          : usersById[data[`${preKey}UpdatedBy`]]?.firstName}{' '}
        on {Moment(data[`${preKey}At`]).format('LL')} at {Moment(data[`${preKey}At`]).format('LT')}
      </Typography>
    )
  }

  const handleRequirementChange = async requirementId => {
    // If requirement has a timestamp, set to null (uncheck)
    const timeStamp = data[`${requirementId}At`] ? null : Moment().format()
    const onboardingRequirementUpdate = {
      [`${requirementId}At`]: timeStamp,
      [`${requirementId}UpdatedBy`]: user.id,
    }
    const newData = await onboardingResource.update(data.patient.id, onboardingRequirementUpdate)
    props.setCurrentOnboardingState(newData)
  }

  const StepIcon = (props: StepIconProps) => {
    const icons: { [index: string]: React.ReactElement } = {
      completed: (
        <Box color="primary.main">
          <CheckCircle color="inherit" />
        </Box>
      ),
      waiting: (
        <Box color="secondary.main">
          <FiberManualRecord color="inherit" />
        </Box>
      ),
      terminated: (
        <Box color="icons.errorRed">
          <Cancel color="inherit" />
        </Box>
      ),
    }
    const iconToStatus = {
      [ONBOARDING_STATES.initialized]: icons.completed,
      [ONBOARDING_STATES.signedup]: icons.completed,
      [ONBOARDING_STATES.enrolled]: icons.completed,
      [ONBOARDING_STATES.member]: icons.completed,
      [ONBOARDING_STATES.deactivated]: icons.terminated,
    }

    // props.icon returns index of status starting from 1
    // get the right icon for the onboarding status
    return (
      <div>
        {indexToKey[Number(props.icon) - 1] === data.status
          ? iconToStatus[data.status]
          : icons.waiting}
      </div>
    )
  }

  const getStepTitle = (step: number) => {
    if (indexToKey[step] === ONBOARDING_STATES.signedup) {
      return (
        <Typography variant="body2" color="inherit" className={classes.upperCase}>
          Requirements For the Next Phase
        </Typography>
      )
    }
  }

  const getStepContent = (step: number) => {
    const requirements = getRequirementsFromIndex(step)
    const multichoiceOptions = [
      { label: 'Unknown', value: 'null' },
      { label: 'Book visit now', value: 'true' },
      { label: 'No thanks', value: 'false' },
    ]
    return (
      <div>
        <Box mt={2} color="grey.600">
          {getStepTitle(step)}
        </Box>
        {requirements.map((requirement, index) => (
          <Box key={`signup_${index}`} mt={2.5} display="flex" alignItems={'center'}>
            {requirement.multichoice ? (
              <Autocomplete
                disabled={true}
                disableClearable
                size="small"
                style={{ width: 200 }}
                value={multichoiceOptions.find(
                  o =>
                    o.value ==
                    (data[requirement.id] == null ? 'null' : data[requirement.id].toString())
                )}
                options={multichoiceOptions}
                getOptionLabel={option => option.label}
                renderInput={params => <TextField {...params} />}
              />
            ) : (
              <CheckBox
                value={data[`${requirement.id}At`]}
                onChecked={() => handleRequirementChange(requirement.id)}
              />
            )}
            <Box ml={1}>
              <Typography variant="body2">
                {'detailLabel' in requirement ? requirement.detailLabel : requirement.label}
              </Typography>
              <Box mt={-0.5} color="grey.600">
                {data[`${requirement.id}At`]
                  ? getStatusTimeStampUpdatedBy(requirement.id, false)
                  : null}
              </Box>
            </Box>
          </Box>
        ))}
      </div>
    )
  }

  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}>
              {label}
              {getRequirementsFromIndex(index).length ? (
                <IconButton className={classes.iconButton} onClick={() => handleExpand(index)}>
                  {index === activeStep ? (
                    <ExpandLess color="primary" />
                  ) : (
                    <ExpandMore color="primary" />
                  )}
                </IconButton>
              ) : (
                <Box mt={2}></Box>
              )}
              {props.statusTimeStamps[index] ? (
                <Box mt={-2} color="grey.600">
                  {getStatusTimeStampUpdatedBy(indexToKey[index], true)}
                </Box>
              ) : null}
            </StepLabel>
            <StepContent>{getStepContent(index)}</StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    iconButton: {
      height: 50,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
  }
})

export default OnboardingStatusStepper
