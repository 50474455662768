import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { PatientUser, Coverage, InsurancePlan, InsurancePayer, Network } from '~/legacy/core'
import { Button, Grid, Typography, Box, Switch, Avatar } from '@mui/material'
import { useFormik } from 'formik'
import { apiClient } from '~/api/rest'
import EditField from '../../Generic/EditField'
import EditPatientInsuranceCard from './EditPatientInsuranceCard'
import InsuranceContractInfo from './InsuranceContractInfo'
import EligibilityCheckResponseDetails, {
  IEligibilityCheckResult,
} from './EligibilityCheckResponseDetails'
import orderBy from 'lodash/orderBy'
import { makeStyles } from 'tss-react/mui'
import { getUserAge } from '~/utils'
import { useQuery } from '~/components/Providers/ApiProvider'
import { useHistory } from 'react-router'

interface EditInsuranceFormProps {
  patient: PatientUser
  loading: boolean
  existingCoverage: Coverage[]
  handleInsuranceSubmit: (values: PatientUser) => void
  handleEligibilityCheck: (values: PatientUser) => void
  lastEligibilityCheckData: IEligibilityCheckResult | null
}

interface CoveredMember {
  firstName: string
  lastName: string
  isPrimarySubscriber: boolean
  memberPersonId: string
  relationship: string
  memberDob: Date
  user?: number
}

const PLAN_TYPES = {
  none: 'none',
  hmo: 'HMO',
  ppo: 'PPO',
}

// API for fetching all covered members
const fetchCoveredMembers = (personId: number) => {
  return apiClient.rest.get<CoveredMember[] | null>(
    `/eligibility/covered-members?person_id=${personId}`
  )
}

export const useCoveredMembers = personId => {
  const listCoveredMembersKey = 'listCoveredMembers'
  return useQuery(
    [listCoveredMembersKey, personId],
    () => fetchCoveredMembers(personId),
    {},
    {
      error: `Failed to fetch covered members for ${personId}`,
    }
  )
}

const useInsurancePayers = () =>
  useQuery(
    ['insurancePayers'],
    (): Promise<InsurancePayer[]> => apiClient.rest.get(`/insurance/provider/payer/`),
    { staleTime: Infinity },
    { error: 'Failed to fetch Insurance Payers' }
  )

const EditInsuranceForm: FC<EditInsuranceFormProps> = props => {
  const [isPlanSelectionDisabled, setIsPlanSelectionDisabled] = useState<boolean>(false)
  const {
    patient,
    loading,
    handleEligibilityCheck,
    handleInsuranceSubmit,
    existingCoverage,
    lastEligibilityCheckData,
  } = props
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: patient,
    onSubmit: async values => {
      values = beforeSubmit(values)
      await handleInsuranceSubmit(values)
      setIsPlanSelectionDisabled(false)
    },
  })

  const {
    person: { insuranceInfo, coverage },
  } = formik.values

  const { data: coveredMembers } = useCoveredMembers(patient.person.id)
  const [plans, setPlans] = useState<InsurancePlan[] | null>([])
  const payers = useInsurancePayers()
  const fetchPlans = async () => {
    if (insuranceInfo?.insurancePayer?.id != null) {
      const plans: InsurancePlan[] = await apiClient.rest.get(
        `/insurance/payer/${insuranceInfo?.insurancePayer?.id}/plans/`
      )
      const sortedPlans = orderBy(plans, plan => plan.name, ['asc'])
      setPlans(sortedPlans)
    }
  }
  useEffect(() => {
    if (!isPlanSelectionDisabled) {
      fetchPlans()
    }
  }, [insuranceInfo?.insurancePayer?.id, isPlanSelectionDisabled])

  const beforeSubmit = values => {
    // Select options can't have a null value, so make sure planType can be nullable
    const planType = values.person.insuranceInfo?.planType
    if (planType === '' || planType === PLAN_TYPES.none)
      values.person.insuranceInfo!.planType = null
    return values
  }

  // Shortcut for change handlers and values
  const setValues = (name: string, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: loading,
    value,
  })

  const disableInsurancePlanSelector = e => {
    e.persist()
    formik.handleChange(e)
    formik.setFieldTouched('person.insuranceInfo.insurancePayerId', true, true)
    formik.setFieldValue('person.insuranceInfo.insurancePayer.id', e.target.value)
    formik.setFieldValue('person.insuranceInfo.insurancePlanId', '')
    setIsPlanSelectionDisabled(true)
  }

  const useStyles = makeStyles()(theme => {
    return {
      avatar: {
        textTransform: 'uppercase',
        margin: '1rem',
        fontSize: '1.5rem',
        width: '4.5rem',
        height: '4.5rem',
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.text.primary,
      },
    }
  })

  const { classes } = useStyles()

  const getNetworksNames = (networks: Array<Network>) => {
    return networks
      ?.map(network => {
        return network?.name
      })
      .join(', ')
  }

  const history = useHistory()
  const handleNavigation = (member: CoveredMember) => {
    if (member.user) history.push(`/patients/${member.user}`)
    else history.push(`/people/${member.memberPersonId}`)
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box mt={3} mb={1}>
        <EditPatientInsuranceCard patient={patient} />
      </Box>
      <Box mt={3} mb={1} display="flex" alignItems="center">
        <Box>
          <Typography variant="h6">Insurance Info</Typography>
        </Box>
      </Box>
      {lastEligibilityCheckData && (
        <Box mb={3}>
          <EligibilityCheckResponseDetails responseData={lastEligibilityCheckData} />
        </Box>
      )}
      <Grid container spacing={2}>
        {existingCoverage.length && (
          <EditField
            {...setValues('person.coverage.name', coverage.name)}
            label="Status"
            select
            SelectProps={{ native: true }}
          >
            {existingCoverage.map(v => (
              <option key={v.name} value={v.name}>
                {v.name}
              </option>
            ))}
          </EditField>
        )}
        <EditField
          {...setValues('person.insuranceInfo.memberId', insuranceInfo?.memberId)}
          label="Member ID"
        />
        <EditField
          {...setValues('person.insuranceInfo.insurancePayerId', insuranceInfo?.insurancePayer?.id)}
          label="Insurance Payer"
          select
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
          onChange={disableInsurancePlanSelector}
        >
          <option value="">none</option>
          {(payers?.data || []).map(payer => (
            <option key={payer.id} value={payer.id}>
              {payer.name}
            </option>
          ))}
        </EditField>
        <EditField
          {...setValues('person.insuranceInfo.insurancePlanId', insuranceInfo?.insurancePlanId)}
          label="Insurance Plan"
          select
          disabled={isPlanSelectionDisabled || loading}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ native: true }}
        >
          <option value="">none</option>
          {plans?.map(plan => (
            <option key={plan.id} value={plan.id}>
              {plan.name}
            </option>
          ))}
        </EditField>
        <EditField
          {...setValues('person.insuranceInfo.groupNumber', insuranceInfo?.groupNumber)}
          label="Group Number"
        />
        <EditField
          {...setValues('person.insuranceInfo.planType', insuranceInfo?.planType)}
          label="Plan Type"
          select
          SelectProps={{ native: true }}
        >
          {Object.entries(PLAN_TYPES).map(([key, val]) => (
            <option key={key} value={key}>
              {val}
            </option>
          ))}
        </EditField>
        <EditField
          {...setValues(
            'person.insuranceInfo.planDescription',
            insuranceInfo?.planDescription ?? ' '
          )}
          label="Plan Description"
          disabled={true}
        />
        <EditField
          {...setValues('person.insuranceInfo.coverageStart', insuranceInfo?.coverageStart ?? ' ')}
          label="Valid Date"
          disabled={true}
        />
        <EditField
          {...setValues('person.insuranceInfo.coverageEnd', insuranceInfo?.coverageEnd ?? ' ')}
          label="Expired Date"
          disabled={true}
        />
        <EditField
          {...setValues('person.insuranceInfo.pcpName', insuranceInfo?.pcpName ?? ' ')}
          label="PCP"
          disabled={true}
        />
      </Grid>
      <Grid container spacing={2}>
        <EditField
          InputLabelProps={{ shrink: true }}
          label="Insurance Plan Networks"
          type="text"
          value={getNetworksNames(insuranceInfo?.insurancePlan?.networks ?? [])}
          disabled={true}
          multiline
        />
      </Grid>
      <Box my={2}>
        <Box display="flex" alignItems="center">
          <Switch
            {...setValues(
              'person.insuranceInfo.isPrimarySubscriber',
              insuranceInfo?.isPrimarySubscriber
            )}
            edge="start"
            checked={!!formik.values.person.insuranceInfo?.isPrimarySubscriber}
            color="primary"
          />
          <Box ml={1}>
            <Typography variant="body2">Patient is Primary Subscriber</Typography>
          </Box>
        </Box>
        {formik.values.person.insuranceInfo?.isPrimarySubscriber === false && (
          <Grid container spacing={2}>
            <EditField
              {...setValues(
                'person.insuranceInfo.primaryFirstName',
                insuranceInfo?.primaryFirstName
              )}
              label="Subscriber First Name"
            />
            <EditField
              {...setValues('person.insuranceInfo.primaryLastName', insuranceInfo?.primaryLastName)}
              label="Subscriber Last Name"
            />
            <EditField
              {...setValues('person.insuranceInfo.primaryDob', insuranceInfo?.primaryDob)}
              label="Subscriber Date of Birth"
              type="date"
            />
          </Grid>
        )}
      </Box>

      <Box my={2}>
        {insuranceInfo ? <InsuranceContractInfo insuranceInfo={insuranceInfo} /> : null}
      </Box>
      {coveredMembers && coveredMembers.length > 0 && (
        <>
          <Box mt={3} mb={1} display="flex" alignItems="center">
            <Box>
              <Typography variant="body2">Covered Members ({coveredMembers?.length})</Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Grid container spacing={3}>
              {coveredMembers?.map(member => {
                return (
                  <Grid item xs={5} md={4} key={member.memberPersonId}>
                    <Box display="flex" flex="1" alignItems="center">
                      <Box mr={1} alignSelf="baseline">
                        <Avatar
                          className={classes.avatar}
                          style={{ margin: 0, display: 'inline-flex' }}
                        >
                          {member.firstName.substr(0, 1) + member.lastName.substr(0, 1)}
                        </Avatar>
                      </Box>
                      <div>
                        <Box display="flex" alignItems="center">
                          <Button
                            style={{ padding: 0 }}
                            onClick={() => {
                              handleNavigation(member)
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="primary"
                              style={{ fontWeight: 'bold' }}
                            >
                              {member.firstName + ' ' + member.lastName}
                            </Typography>
                          </Button>
                        </Box>
                        <Box color="black">
                          <Typography variant="caption">{member.relationship}</Typography>
                          {getUserAge(member.memberDob) < 18 && (
                            <Typography variant="caption">{' <18'}</Typography>
                          )}
                        </Box>
                        {member.isPrimarySubscriber && (
                          <Box color="black" fontStyle="italic" fontSize="1.25rem">
                            {'Primary Subscriber'}
                          </Box>
                        )}
                      </div>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </>
      )}
      <Grid container spacing={1} justifyContent="flex-end">
        {formik.dirty && (
          <Grid item>
            <Button onClick={() => formik.resetForm()}>Cancel Insurance Changes</Button>
          </Grid>
        )}
        <Grid item>
          <Button type="submit" color="primary" variant="outlined">
            Save Insurance
          </Button>
        </Grid>
        {patient.id && (
          <Grid item>
            <Button
              onClick={async () => {
                await handleEligibilityCheck(formik.values)
                setIsPlanSelectionDisabled(false)
              }}
              color="primary"
              variant="outlined"
            >
              Save and Check Eligibility
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  )
}

export default EditInsuranceForm
