import { useQuery } from 'react-query'

import { apiClient } from '~/api/rest'
import { getServices, LocationType } from '~/api/SteerageService'
import { PRIMARY_CARE_PROGRAM_UID } from '~/constants/programEnrollment'
import usePatientDetail from '../../utils/usePatientDetail'
import { Service, SpecialtyDropdownOption, SpecialtyGroup } from '../types'

/**
 * Loads all dependencies for the Referral Details section of the Steerage View.
 * Caches indefinitely and performs the necessary mutations to get specialty groups
 * and location types into a single array.
 */
export const useReferralDetails = (): {
  isLoading: boolean
  specialtiesAndLocationTypes: SpecialtyDropdownOption[]
  services: Service[]
} => {
  const { patientIsInProgram } = usePatientDetail()
  const isInPrimaryCareProgram = patientIsInProgram(PRIMARY_CARE_PROGRAM_UID)

  const query = useQuery(
    ['referralDetails'],
    () =>
      Promise.all([
        apiClient.rest.get<LocationType[]>('/facts/location-types/'),
        apiClient.rest.get<SpecialtyGroup[]>('/facts/specialty-groups/'),
        getServices(),
      ]),
    { staleTime: Infinity }
  )

  // Return default values if the query is loading or there's no data
  if (query.isLoading || !query.data) {
    return {
      isLoading: true,
      specialtiesAndLocationTypes: [],
      services: [],
    }
  }

  const [locationTypes, specialtyGroups, services] = query.data
  let specialtiesAndLocationTypes: SpecialtyDropdownOption[] = []

  // Add SpecialtyGroups
  specialtiesAndLocationTypes = specialtiesAndLocationTypes.concat(
    specialtyGroups.map(group => ({
      id: group.id,
      label: group.label,
      source: 'SpecialtyGroup',
      services: group.services ?? [],
      clinicalFocusAreas: group.clinicalFocusAreas ?? [],
      isDeprecated: group.isDeprecated ?? false,
      doesVirtualPartnerExist: group.doesVirtualPartnerExist ?? false,
    }))
  )

  // Add LocationTypes
  specialtiesAndLocationTypes = specialtiesAndLocationTypes.concat(
    locationTypes.map(type => {
      /**
       * For Primary Care patients, we want to override the label for the "Urgent Care"
       * location type to "Firefly Nearby". For non-Primary Care patients, we want to
       * hide all services for the "Urgent Care" location type.
       */
      let labelOverride = type.label
      let servicesOverride = type.services ?? []
      if (labelOverride === 'Urgent Care' && isInPrimaryCareProgram) {
        labelOverride = 'FFNB / Urgent Care'
      }
      if (labelOverride === 'Remote Testing' && isInPrimaryCareProgram) {
        labelOverride = 'FFNB / Remote Testing'
      }
      if (labelOverride === 'Urgent Care' || labelOverride === 'Remote Testing') {
        servicesOverride = []
      }
      return {
        id: type.id,
        label: labelOverride,
        source: 'LocationType',
        services: servicesOverride,
        isDeprecated: type.isDeprecated ?? false,
        doesVirtualPartnerExist: type.doesVirtualPartnerExist ?? false,
      }
    })
  )

  // Sort the array by label
  specialtiesAndLocationTypes.sort((a, b) => a.label.localeCompare(b.label))

  return { isLoading: false, specialtiesAndLocationTypes, services }
}
