import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Chip, Drawer, Fade, Grid } from '@mui/material'
import {
  useWorkUnitItems,
  useWorkListsById,
  useUpdateWorkUnitItem,
} from '../../api/WorkListsService'
import Moment from 'moment'

import {
  DataGridPro,
  GridColDef,
  GridCellParams,
  GridToolbar,
  GridValueFormatterParams,
  GridFilterModel,
  GridLinkOperator,
  useGridApiRef,
  GridFilterItem,
  GridRowModel,
  GridRowsProp,
  GridRowParams,
  GridColumnResizeParams,
  GridFilterOperator,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import WorklistLinks from './WorklistLinks'
import UserGroupAvatar from '../Generic/UserGroupAvatar'
import EditableUserDataCell from './EditableUserDataCell'
import { WorkUnitRowData } from './WorklistTable'
import { useCaseCategoriesById } from '../../api/CaseService'
import { getUserById } from '../../utils'
import worklistPreferencesSlice, {
  FilterItem,
  WorklistPreferences,
} from '../../redux/slices/worklistpreferences'
import Loader from '../Loader'
import PHIAccessBlock from '../PHIAccessBlock'
import { PatientDetailContextProvider } from '../PatientDetail/utils/patientDetailContext'
import WorklistEditor from './WorklistEditor'
import {
  worklistCareTeamFilterOperators,
  worklistNextActionDateFilterOperators,
  WorklistRowBuilder,
} from './utils'
import { useTypedWorklistSelector, useWorklistDispatch } from '../../redux/slices/worklists'
import { educationSlice } from '~/redux/slices/education'
import { useDispatch } from 'react-redux'
import { WorklistProps } from './WorklistTableSelector'
import CaseTag from '../PatientCategorizedTodos/CaseTag'

const drawerWidth = '40%'
const fieldType = 'item'
interface FilterItemWithType extends FilterItem {
  type: string
}

interface WorkunitTableSort {
  field: string
  sort: string
  type: string
}

const TagDataCell = (params: GridCellParams) =>
  params.value?.map(caseTag => {
    return <CaseTag key={String(caseTag.id)} tag={caseTag} />
  })

export interface WorkUnitGridRow extends WorkUnitRowData, GridRowModel {}

export const workUnitFields: {
  prop: keyof WorkUnitRowData
  headerName: string
  dataType: string
  width: number
  filterable: boolean
  sortable: boolean
  filterOperators?: GridFilterOperator[]
  renderCell?: (params: any) => React.ReactNode
}[] = [
  {
    prop: 'tags',
    headerName: 'Tags',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: false,
    renderCell: TagDataCell,
  },
  {
    prop: 'person',
    headerName: 'Person',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    prop: 'category',
    headerName: 'Case Category',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    prop: 'description',
    headerName: 'Description',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    prop: 'notes',
    headerName: 'Notes',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    prop: 'createdAt',
    headerName: 'Case Created',
    dataType: 'date',
    width: 200,
    filterable: true,
    sortable: true,
  },
  {
    prop: 'careTeam',
    headerName: 'Care Team',
    dataType: 'string',
    width: 200,
    filterable: true,
    filterOperators: worklistCareTeamFilterOperators,
    sortable: false,
  },
  {
    prop: 'podNames',
    headerName: 'Care Pod',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: false,
  },
  {
    prop: 'statusCategory',
    headerName: 'Status Category',
    dataType: 'string',
    width: 200,
    filterable: true,
    sortable: false,
  },
]

// TODO: remove redux dependency and unused codes
// TODO: define types for any objects
export const WorkUnitTable: FC<WorklistProps> = (props: WorklistProps) => {
  const { classes } = useStyles()
  const [itemCount, setItemCount] = useState<number>(0)
  const [rows, setRows] = useState<GridRowsProp>([])
  const [columns, setColumns] = useState<GridColDef[]>([])
  const [options, setOptions] = useState<any>({})
  const [filters, setFilters] = useState<any>(undefined)
  const [worklistSort, setWorklistSort] = useState<any>(undefined)
  const [worklistDataloading, setWorklistDataloading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [modalData, setModalData] = useState<any>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [worklistMetaDataloading, setWorklistMetaDataloading] = useState<boolean>(false)
  const [title, setWorklistTitle] = useState<string>('')
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()
  const worklistDispatch = useWorklistDispatch()
  const { result: workListsById, isLoading: isWorklistLoading } = useWorkListsById()
  const { result: workUnits, isLoading: isWorkUnitLoading } = useWorkUnitItems(props.worklistId, {
    ...options,
  })
  const { result: caseCategoriesById, isLoading: isCaseCategoryLoading } = useCaseCategoriesById()
  const { isLoading: isUpdatingWorkUnit, mutateAsync: handleUpdateWorkUnit } =
    useUpdateWorkUnitItem()
  const worklistPreferences = useTypedWorklistSelector(state =>
    props.worklistId ? state.worklistPreferences.byId[props.worklistId] : {}
  )
  const rowsPerPage = 50
  const isLoading =
    isWorklistLoading || isWorkUnitLoading || isCaseCategoryLoading || isUpdatingWorkUnit

  const workList = useMemo(
    () => (props.worklistId ? workListsById[props.worklistId] : undefined),
    [props.worklistId, workListsById]
  )

  useEffect(() => {
    setPage(0)
  }, [props.worklistId])

  useEffect(() => {
    setOptions({
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      isClosed: false,
      filters: filters,
      sort: worklistSort,
    })
  }, [page, filters, worklistSort])

  const handleDueDateEdit = async (workUnit: WorkUnitGridRow, field: string, value: any) => {
    await handleUpdateWorkUnit({
      id: Number(workUnit.id),
      contentType: workUnit.contentType,
      dueDate: Moment(value).format(),
      worklist: Number((workUnit.worklist as any).id),
    })
  }

  const createColumns = (preferences: WorklistPreferences): GridColDef[] => {
    const columns: GridColDef[] = []
    const columnVisibilityPreferencesForWorklist = preferences?.visibility || {}
    const columnWidthPreferencesForWorklist = preferences?.width || {}

    columns.push({
      field: 'data',
      headerName: 'Links',
      sortable: false,
      filterable: false,
      width: columnWidthPreferencesForWorklist?.data?.width || 200,
      hide:
        columnVisibilityPreferencesForWorklist.data &&
        !columnVisibilityPreferencesForWorklist.data.isVisible,
      // eslint-disable-next-line react/display-name
      renderCell: params => (
        <WorklistLinks
          showEditLink={true}
          onEditTask={() => {
            setModalData(params.value)
            setModalOpen(true)
          }}
          {...params}
        />
      ),
    })
    columns.push({
      field: 'status',
      headerName: 'Status',
      sortable: true,
      width: columnWidthPreferencesForWorklist?.status?.width || 150,
      hide:
        columnVisibilityPreferencesForWorklist.status &&
        !columnVisibilityPreferencesForWorklist.status.isVisible,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <Chip label={String(params.value).toUpperCase()} />
        ) : (
          <Box display="flex" margin="1rem" padding="1rem">
            -
          </Box>
        ),
    })
    columns.push({
      field: 'assignedToName',
      headerName: 'Owner',
      sortable: false,
      width: columnWidthPreferencesForWorklist?.assignedTo?.width || 100,
      hide:
        columnVisibilityPreferencesForWorklist.assignedTo &&
        !columnVisibilityPreferencesForWorklist.assignedTo.isVisible,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <UserGroupAvatar
            id={params.row.ownerGroup as number}
            size="small"
            avatarStyle={{ margin: 0, display: 'inline-flex' }}
          />
        ) : (
          <Box display="flex" margin="1rem" padding="1rem">
            -
          </Box>
        ),
    })
    columns.push({
      type: 'date',
      field: 'dueDate',
      headerName: 'Next Action Date',
      sortable: true,
      filterable: true,
      filterOperators: worklistNextActionDateFilterOperators,
      width: columnWidthPreferencesForWorklist?.dueDate?.width || 182,
      hide:
        columnVisibilityPreferencesForWorklist.dueDate &&
        !columnVisibilityPreferencesForWorklist.dueDate.isVisible,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridCellParams) => (
        <EditableUserDataCell
          editable={true}
          field="dueDate"
          handleOnEdit={handleDueDateEdit}
          value={params.value}
          worklistRowData={(params.row as WorkUnitGridRow).data!}
        />
      ),
    })
    for (const workUnitDatum of workUnitFields) {
      const {
        prop,
        headerName,
        dataType,
        width,
        filterable,
        filterOperators,
        sortable,
        renderCell,
      } = workUnitDatum
      let currentColumnDefinition: GridColDef = {
        type: dataType,
        field: prop as string,
        headerName: headerName,
        sortable: sortable,
        filterable: filterable,
        width: columnWidthPreferencesForWorklist[prop]
          ? columnWidthPreferencesForWorklist[prop].width
          : width,
        hide:
          columnVisibilityPreferencesForWorklist[prop] &&
          !columnVisibilityPreferencesForWorklist[prop].isVisible,
        valueFormatter: (params: GridValueFormatterParams) =>
          params.value != null ? params.value : '-',
        renderCell: renderCell != null ? renderCell : ReadOnlyLookerDataCell,
      }
      if (filterOperators) {
        currentColumnDefinition = {
          ...currentColumnDefinition,
          filterOperators: filterOperators,
        }
      }
      columns.push(currentColumnDefinition)
    }
    return columns
  }

  useEffect(() => {
    setWorklistMetaDataloading(true)
    if (workList) {
      setWorklistTitle(workList.name)
      setColumns(createColumns(worklistPreferences))
      setWorklistMetaDataloading(false)
    }
  }, [workList, props.worklistId])

  useEffect(() => {
    if (apiRef.current != null && !worklistDataloading && !isLoading && workList) {
      apiRef.current.setSortModel([])
      const worklistFilters =
        props.worklistFiltersUrlParam?.worklistId === props.worklistId &&
        props.worklistFiltersUrlParam?.filters
          ? props.worklistFiltersUrlParam?.filters
          : worklistPreferences?.filter
      if (worklistFilters) {
        const items: GridFilterItem[] = []
        for (const item of worklistFilters.items) {
          items.push({
            columnField: item.field,
            operatorValue: item.operator,
            value: item.value,
          })
        }
        try {
          apiRef.current.setFilterModel({
            items: items,
            linkOperator: worklistFilters.linkOperator as GridLinkOperator,
          })
        } catch (error) {
          // If it fails to set the filters then reset the filters
          apiRef.current.setFilterModel({ items: [] })
        }
      } else {
        apiRef.current.setFilterModel({ items: [] })
      }
    }
  }, [props.worklistId, worklistDataloading, isLoading, workList])

  useEffect(() => {
    setWorklistDataloading(true)
    // start filling up the rows only when worklist metadata has completed loading
    const worklistLoaded =
      workList &&
      workUnits &&
      !isLoading &&
      !worklistMetaDataloading &&
      props.providers &&
      props.providers?.length > 0

    if (worklistLoaded) {
      setItemCount(workUnits.count)
      setRows(
        new WorklistRowBuilder({
          worklist: workList,
          items: workUnits.items,
          providers: props.providers,
          assigneeGroups: props.assigneeGroups,
          getUserById,
          caseCategoriesById: caseCategoriesById,
        }).createRows()
      )
    }
    setWorklistDataloading(false)
  }, [workList, workUnits, isLoading, props.providers, worklistMetaDataloading])

  // Fire side effects when a row is selected
  const handleRowSelected = useCallback((params: GridRowParams<any>) => {
    // If a row represents a patient, consider that patient "in focus"
    if (params.row?.data?.patientId) {
      dispatch(educationSlice.actions.focusPatient(params.row.data.patientId))
    }
  }, [])
  // Be sure to blur patient when leaving worklist views
  useEffect(
    () => () => {
      dispatch(educationSlice.actions.blurPatient())
    },
    []
  )

  const hideWorklistDetails = () => {
    setModalOpen(false)
    setModalData(null)
  }

  const handleColumnVisibility = (params: any) => {
    if (props.worklistId) {
      worklistDispatch(
        worklistPreferencesSlice.actions.setVisibility({
          worklistId: props.worklistId,
          field: params.field,
          isVisible: params.isVisible,
        })
      )
    }
  }

  const handleColumnFilter = (params: GridFilterModel) => {
    if (props.worklistId) {
      const items: FilterItem[] = []
      const itemsWithType: FilterItemWithType[] = []
      for (const item of params.items) {
        if (item.columnField && item.operatorValue) {
          const filterItem = {
            field: item.columnField,
            operator: item.operatorValue,
            value: item.value,
          }
          items.push(filterItem)
          if (fieldType) {
            itemsWithType.push({ ...filterItem, type: fieldType })
          }
        }
      }
      const selectedFilters = {
        linkOperator: params.linkOperator || GridLinkOperator.Or,
        items: items,
      }
      worklistDispatch(
        worklistPreferencesSlice.actions.setFilter({
          worklistId: props.worklistId,
          filter: selectedFilters,
        })
      )
      props.setWorklistFiltersUrlParam(
        items.length > 0
          ? {
              worklistId: props.worklistId,
              filters: selectedFilters,
            }
          : undefined
      )
      setFilters(selectedFilters)
    }
  }

  const handleColumnWidth = (param: GridColumnResizeParams) => {
    if (props.worklistId && param.colDef) {
      worklistDispatch(
        worklistPreferencesSlice.actions.setWidth({
          worklistId: props.worklistId,
          field: param.colDef.field,
          width: param.width,
        })
      )
    }
  }

  const handleColumnSort = (model: GridSortModel) => {
    if (props.worklistId) {
      let worklistSort: WorkunitTableSort | undefined = undefined
      if (model.length > 0) {
        const sortModel = model[0]
        worklistSort = { ...sortModel, type: fieldType } as WorkunitTableSort
        setWorklistSort(worklistSort)
      }
    }
  }

  if (worklistDataloading) {
    return <Loader />
  }

  return (
    <div className={classes.container}>
      <main className={classes.content}>
        <div className={classes.containerTitle}>
          <Grid container direction="row" alignItems="flex-end">
            <Grid item xs={8}>
              {title}
            </Grid>
          </Grid>
        </div>
        <div className={classes.containerRows}>
          <DataGridPro
            rowCount={itemCount}
            pagination
            page={page}
            paginationMode="server"
            pageSize={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            onPageChange={newPage => setPage(newPage)}
            columns={columns}
            disableMultipleSelection
            onRowClick={handleRowSelected}
            onColumnVisibilityChange={handleColumnVisibility}
            onFilterModelChange={handleColumnFilter}
            filterMode="server"
            onColumnWidthChange={handleColumnWidth}
            onSortModelChange={handleColumnSort}
            sortingMode="server"
            rows={rows}
            loading={isLoading || worklistMetaDataloading}
            columnBuffer={10}
            apiRef={apiRef}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div>
      </main>
      {modalData !== null && modalOpen && (
        <Fade in={true}>
          <div>
            <Drawer
              anchor={'right'}
              className={classes.drawer}
              variant="persistent"
              classes={{
                paper: classes.drawerPaper,
              }}
              open={true}
            >
              <PHIAccessBlock userId={modalData.patientId || null}>
                <PatientDetailContextProvider>
                  <WorklistEditor
                    worklistRow={modalData}
                    handleClose={hideWorklistDetails}
                    isWorklistWithWorkUnits={false}
                  />
                </PatientDetailContextProvider>
              </PHIAccessBlock>
            </Drawer>
          </div>
        </Fade>
      )}
    </div>
  )
}

// TODO: move as Box component if possible
const useStyles = makeStyles()(theme => {
  return {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    containerTitle: {
      fontSize: '3rem',
      padding: theme.spacing('1.5em', '0em', '0.5em', '1em'),
    },
    containerRows: {
      flex: 1,
      height: '85%',
      overflow: 'auto',
    },
    highlightedCell: {
      fontWeight: 'bold',
    },
    emptyCell: {
      display: 'flex',
      margin: '1rem',
      padding: '1rem',
    },
    save: {
      marginLeft: theme.spacing(),
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
    worklistSubText: {
      color: theme.palette.grey[900],
      fontSize: '1.2rem',
      textAlign: 'right',
      marginRight: '1em',
    },
    drawer: {
      width: drawerWidth,
      height: '100%',
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
    },
    contentShift: {
      flexGrow: 1,
      marginRight: `calc(${drawerWidth} + 1%)`,
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    tabsTab: {
      textTransform: 'uppercase',
      fontSize: '1.4rem',
      padding: '0 1em',
    },
    tabsTabSelected: {
      color: theme.palette.primary.main,
    },
  }
})

const ReadOnlyLookerDataCell = (params: GridCellParams) => (
  <div title={params.value?.toString()}>{params.value?.toString()}</div>
)

export default WorkUnitTable
