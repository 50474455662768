import { FC, useState } from 'react'
import Moment from 'moment'
import SmsFailedIcon from '@mui/icons-material/SmsFailed'

import { Box, Tooltip, Typography } from '@mui/material'
import { RichTextEditorDisplay } from '../RichTextEditor/RichTextEditorDisplay'

interface EmailMessageDisplayProps {
  content: string
  to_address: string[]
  from_address: string
  send_at: string
  maxLength?: number
  key: number
  status: string
}

export const EmailMessageDisplay: FC<EmailMessageDisplayProps> = props => {
  const date = Moment(new Date(props.send_at))
  const messageDate = date.format('MMM. D, YYYY h:mm a')
  const [maxLength, setMaxLength] = useState(props.maxLength)
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      onClick={() => (maxLength ? setMaxLength(undefined) : setMaxLength(props.maxLength))}
      key={props.key}
      mt={2}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexGrow={1}
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" justifyContent="space-around">
          <Typography fontSize={13} fontWeight="bold">
            {props.from_address}
          </Typography>
          <Typography fontSize={13}>to {props.to_address[0]}</Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-around" justifyItems="end">
          <Typography fontSize={12}>{messageDate}</Typography>
        </Box>
        {props.status === 'FAILED' && (
          <Tooltip title={props.status}>
            <SmsFailedIcon color="error" />
          </Tooltip>
        )}
      </Box>
      <RichTextEditorDisplay content={props.content} maxLength={maxLength} />
    </Box>
  )
}
