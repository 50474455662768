import Moment from 'moment'
import { FormSubmission, Task } from '~/legacy/core'

// Probably good enough
// https://stackoverflow.com/a/34405528
const getTimeZoneAbbreviation = () =>
  new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]

export const formatDateTimeDay = (dateTime: string) => Moment(dateTime).format('MMM. D, YYYY')
export const formatDateTimeFull = (dateTime: string) =>
  Moment(dateTime).format('MMM. D, YYYY h:mm A') + ' ' + getTimeZoneAbbreviation()

export const getIsVirtualTask = taskOrForm => !!taskOrForm?.collectionId

export const getTaskStatus = (task: Task) => (task.isComplete ? 'Complete' : 'Incomplete')

export const getFormSubmissionStatus = (submission: FormSubmission) => {
  if (submission.completedAt) {
    return 'Complete'
  }
  if (submission.expiredAt) {
    return 'Expired'
  }
  if (!submission.startedAt) {
    return 'Not Started'
  }
  if (!submission.completedAt) {
    return 'In Progress'
  }
  return 'Unknown'
}
