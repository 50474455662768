import { Box, Chip, Typography } from '@mui/material'
import { parse } from 'date-fns'
import { groupBy, partition } from 'lodash'
import { FC, Fragment } from 'react'
import { AvailablePhysicianSlot } from '~/api/AppointmentService'
import { DYNAMIC_SCHEDULE_V2 } from '~/components/Appointment/utils'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import Moment from 'moment'
import { getDateTime } from '../utils'

const FULL_DATE_STR_FORMAT = 'ddd, MMM D'
const NoAvailability = () => {
  return (
    <Box bgcolor={'#F2F4F8'} display={'inline-flex'} p={1} flexWrap={'wrap'}>
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#071F3E',
          fontWeight: 500,
        }}
      >
        {'No upcoming availability'}
      </Typography>
    </Box>
  )
}
const FirstAvailable = ({ firstAvailableSlot, setDate }) => {
  return (
    <Box bgcolor={'#F2F4F8'} display={'inline-flex'} p={1} flexWrap={'wrap'} flexDirection={'row'}>
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: 'navy.500',
          fontWeight: 500,
        }}
      >
        {'First available:'}&nbsp;
      </Typography>
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: 'navy.500',
          fontWeight: 500,
          textDecorationLine: 'underline',
        }}
        onClick={() => {
          const now = new Date()
          setDate(getDateTime(new Date(firstAvailableSlot), now))
        }}
      >
        {Moment(firstAvailableSlot).format(FULL_DATE_STR_FORMAT)}
      </Typography>
    </Box>
  )
}

type PhysicianSlotPickerGroupByDayProps = {
  setDate: (value: Date) => void
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
  careTeamSlots: boolean
}

const physicianDisplayName = (slot: AvailablePhysicianSlot) =>
  `${slot.physicianFirstName} ${slot.physicianLastName}, ${slot.physicianTitle}`

const PhysicianSlotPickerGroupByDay: FC<PhysicianSlotPickerGroupByDayProps> = ({
  setDate,
  slots,
  value,
  onChange,
  careTeamSlots,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)

  let slotsByPhysician
  if (isDynamicSchedulingV2Enabled) {
    slotsByPhysician = slots.reduce((app, physician) => {
      if (physician.physicianName && physician.slots) {
        app[physician.physicianName] = {
          slots: physician.slots,
          physicianId: physician.physicianId,
          reason: physician.reason,
          firstAvailableSlot: physician.firstAvailableSlot,
        }
      }
      return app
    }, {})
  } else {
    slotsByPhysician = groupBy(slots, physicianDisplayName)
  }

  const sorted = Object.keys(slotsByPhysician).sort() // alphabetical order of physicians

  return (
    <>
      {sorted.map(displayName => {
        const physicianSlots = isDynamicSchedulingV2Enabled
          ? slotsByPhysician[displayName].slots
          : slotsByPhysician[displayName]
        const physicianId = isDynamicSchedulingV2Enabled
          ? slotsByPhysician[displayName].physicianId
          : null
        const reason = isDynamicSchedulingV2Enabled ? slotsByPhysician[displayName].reason : null
        const firstAvailableSlot: string = isDynamicSchedulingV2Enabled
          ? slotsByPhysician[displayName].firstAvailableSlot
          : null
        const slotsEl = physicianSlots.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: 2,
              marginTop: 1,
              flexWrap: 'wrap',
              rowGap: 1,
            }}
          >
            <PhysicianSlotPicker
              slots={physicianSlots}
              physicianId={physicianId}
              reason={reason}
              value={value}
              onChange={onChange}
            />
          </Box>
        ) : firstAvailableSlot ? (
          <FirstAvailable firstAvailableSlot={firstAvailableSlot} setDate={setDate} />
        ) : (
          <NoAvailability />
        )
        return physicianSlots.length || careTeamSlots ? (
          <Fragment key={displayName}>
            <Typography sx={{ fontSize: '1.4rem', marginTop: 2, fontWeight: 500 }}>
              {displayName}
            </Typography>
            {slotsEl}
          </Fragment>
        ) : null
      })}
    </>
  )
}

type PhysicianSlotPickerProps = {
  slots: AvailablePhysicianSlot[] | string[]
  physicianId: number | null
  reason: string | null
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPicker: FC<PhysicianSlotPickerProps> = ({
  slots,
  physicianId,
  reason,
  value,
  onChange,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)

  return (
    <>
      {slots.map(slot => {
        const slotDetails = isDynamicSchedulingV2Enabled ? slot : slot.scheduledDate
        const time = parse(slotDetails).toLocaleTimeString(undefined, {
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        })
        return (
          <Fragment key={isDynamicSchedulingV2Enabled ? `${physicianId}-${slot}` : slot.id}>
            <Chip
              label={time}
              onClick={() =>
                onChange(
                  isDynamicSchedulingV2Enabled
                    ? {
                        scheduledDate: slot,
                        physicianId: physicianId,
                        reason: reason,
                      }
                    : slot
                )
              }
              color={
                value &&
                (value === slot ||
                  (value.physicianId === physicianId && value.scheduledDate === slot))
                  ? 'primary'
                  : 'default'
              }
              variant="outlined"
              sx={{
                padding: 0,
                marginRight: 1,
                '&.MuiButtonBase-root.MuiChip-colorPrimary': {
                  backgroundColor: '#071F3E',
                  color: 'white',
                  borderColor: '#071F3E',
                },
                '&.MuiButtonBase-root.MuiChip-colorDefault': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}

type PhysicianSlotPickerGroupsByDayProps = {
  setDate: (value: Date) => void
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPickerGroupsByDay: FC<PhysicianSlotPickerGroupsByDayProps> = props => {
  const slots = props.slots
  const [careTeamSlots, othersSlots] = partition(slots, slot => slot.inCareTeam)

  const careTeamEl = careTeamSlots.length ? (
    <Box
      sx={{
        marginTop: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#4D5358',
        }}
      >
        {"Patient's care team:"}
      </Typography>
      <PhysicianSlotPickerGroupByDay {...props} slots={careTeamSlots} careTeamSlots={true} />
    </Box>
  ) : null

  // Show other providers title only when there is atleast one provider with an available slot
  let atleastOneSlotAvailable = false
  const slotsByPhysician = othersSlots.reduce((app, physician) => {
    if (physician.physicianName && physician.slots) {
      app[physician.physicianName] = {
        slots: physician.slots,
        physicianId: physician.physicianId,
        reason: physician.reason,
        firstAvailableSlot: physician.firstAvailableSlot,
      }
    }
    return app
  }, {})
  const keys = Object.keys(slotsByPhysician)
  keys.map(displayName => {
    const physicianSlots = slotsByPhysician[displayName].slots
    atleastOneSlotAvailable = physicianSlots.length ? true : false
  })

  const othersEl =
    othersSlots.length && atleastOneSlotAvailable ? (
      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: '1.3rem',
            color: '#4D5358',
          }}
        >
          {"Other providers licensed in patient's state:"}
        </Typography>
        <PhysicianSlotPickerGroupByDay {...props} slots={othersSlots} careTeamSlots={false} />
      </Box>
    ) : null

  // Should show below text when there is no care team provider and other providers available
  return (
    <>
      {slots.length == 0 || (careTeamSlots.length === 0 && !atleastOneSlotAvailable) ? (
        <Typography sx={{ marginTop: 1 }}>No clinician available</Typography>
      ) : (
        <Box>
          {careTeamEl}
          {othersEl}
        </Box>
      )}
    </>
  )
}

export default PhysicianSlotPickerGroupsByDay
