import { Box, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Moment } from 'moment'
import moment from 'moment'
import { makeStyles } from 'tss-react/mui'
import WeekChips from './WeekChips'
import { getDateTime } from '../utils'

type SelectDateProps = {
  now: Date
  date: Date
  setDate: (value: Date) => void
  datesWithSlotAvailability?: string[]
}

const SelectDate: FC<SelectDateProps> = ({
  date,
  setDate,
  now,
  datesWithSlotAvailability = [],
}) => {
  const { classes } = useStyles()
  return (
    <>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '2rem',
          }}
        >
          {date.toLocaleString('en-EN', { month: 'long' }) + ' ' + date.getFullYear()}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2,
          marginLeft: -1,
        }}
      >
        <WeekChips
          key={date.toDateString()}
          value={date}
          onChange={changedDate => {
            setDate(getDateTime(changedDate, now))
          }}
          now={now}
          datesWithSlotAvailability={datesWithSlotAvailability}
        />
      </Box>
      <Box
        sx={{
          '& .MuiTextField-root .MuiInputBase-colorPrimary::before': {
            border: 'none',
          },
          '& .MuiTextField-root .MuiInput-root.MuiInputBase-root::after': {
            border: 'none',
          },
          '& .MuiTextField-root input': {
            marginLeft: 1,
          },
          '& .MuiInputAdornment-root': {
            marginLeft: 0,
          },
          marginLeft: -1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={date}
            inputFormat="dddd, MMMM D"
            onChange={(date: Moment | null) => {
              if (date) {
                var changedDate = date.toDate()
                setDate(getDateTime(changedDate, now))
              }
            }}
            renderInput={params => (
              <TextField {...params} id="date-picker-inline" variant="standard" />
            )}
            minDate={moment(now)}
            InputProps={{
              classes: { root: classes.root },
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  )
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      'flex-direction': 'row-reverse',
    },
  }
})

export default SelectDate
