import { useRef, useState } from 'react'
import type { FC } from 'react'
import { Tabs, Tab } from '@mui/material'
import { Link, useLocation, useRouteMatch, Switch, Route } from 'react-router-dom'
import CarePlans from '~/components/Careplans/CarePlans'
import PatientDetailAppointments from './PatientDetailAppointments'
import PatientDetailMeta from './PatientDetailMeta'
import EditPatient from './EditPatient/EditPatient'
import PatientDetailForms from './PatientDetailForms'
import ProviderSearch from '~/components/PatientDetail/ProviderSearch'
import PatientDetailOnboarding from './PatientDetailOnboarding'
import PatientNotes from './PatientNotes'
import { useFeatureSwitches } from '~/utils/useFeatureSwitch'
import PatientDetailTimeline from './PatientDetailTimeline'
import PriorAuthorization from '~/components/PatientDetail/PriorAuthorization'
import { PatientTaskList } from '~/components/Tasks/PatientTaskList'
import type { PatientUser } from '~/types'
import { FeatureAccess, Features } from '~/legacy/core'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import Navigation from '~/components/PatientDetail/Navigation'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorScreen from '~/components/ErrorScreen'
import ZusAggregatedProfile from './ZusAggregatedProfile'
import { useFeatureFlags } from '~/utils/useFeatureFlag'
import ProgramTab from './PatientPrograms/ProgramTab'

export interface PatientDetailTabsProps {
  patient: PatientUser
}

const TABS: { [tabName: string]: PatientTab } = {
  'To Dos': {
    component: PatientTaskList,
    to: '/todos',
    feature: 'tasks',
  },
  'Care Plans': {
    component: CarePlans,
    to: '/careplans',
    feature: 'carePlans',
  },
  Profile: {
    component: EditPatient,
    to: '/profile',
  },
  Programs: {
    component: ProgramTab,
    to: '/programs',
    feature: 'programs',
  },
  Appointments: {
    component: PatientDetailAppointments,
    to: '/appointments',
    feature: 'appointmentBooking',
  },
  ZAP: {
    component: ZusAggregatedProfile,
    to: '/zap',
  },
  'Provider Search': {
    component: ProviderSearch,
    to: '/providersearch',
    feature: 'waiver',
  },
  Navigation: {
    component: Navigation,
    to: '/navigation',
    feature: 'waiver',
  },
  'Prior Authorization': {
    component: PriorAuthorization,
    to: '/priorauthorization',
    feature: 'priorAuth',
  },
  Forms: {
    component: PatientDetailForms,
    to: '/forms',
    feature: 'forms',
  },
  Notes: {
    component: PatientNotes,
    to: '/notes',
    feature: 'notes',
  },
  Onboarding: {
    component: PatientDetailOnboarding,
    to: '/onboarding',
    feature: 'onboarding',
  },
  Timeline: {
    component: PatientDetailTimeline,
    to: '/timeline',
  },
  Device: {
    component: PatientDetailMeta,
    to: '/meta',
    feature: 'practiceSettings',
  },
}
const TAB_KEYS = Object.keys(TABS)

// Matches /patients/:id/:tabName
const TAB_REGEX = /\/patients\/(\d+)\/([^/]+)/

const matchPathname = location => location.pathname.match(TAB_REGEX)?.[0]

interface PatientTab {
  component: any
  to: string
  feature?: Features
  featureFlag?: string
  featureSwitch?: string
}

const tabIsEnabled = (
  tab: PatientTab,
  featureFlags: { [name: string]: { isActive: boolean } } | undefined,
  featureSwitches,
  featureAccess: FeatureAccess | undefined
) =>
  (tab.featureSwitch ? featureSwitches?.[tab.featureSwitch]?.active : true) &&
  (tab.featureFlag ? featureFlags?.[tab.featureFlag]?.isActive : true) &&
  (tab.feature ? featureAccess?.tenant?.[tab.feature] : true)

export const PatientDetailTabs: FC<PatientDetailTabsProps> = props => {
  const featureSwitches = useFeatureSwitches().data?.featureSwitches
  const featureFlags = useFeatureFlags().data?.featureFlagsByName

  const location = useLocation()
  const match = useRouteMatch()
  // Pass down scrolling information to child components that want it, e.g., for pagination.
  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrollEventTarget] = useState(new EventTarget())
  const featureAccess = useFeatureAccess()

  // Filter out any tabs with nonexistent or inactive feature switches
  // Also filter out any tabs which the user should not have feature access to
  const activeTabs = TAB_KEYS.filter(v =>
    tabIsEnabled(TABS[v], featureFlags, featureSwitches, featureAccess)
  )

  return (
    <>
      <Tabs
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={matchPathname(location)}
        sx={{
          minWidth: 80,
          minHeight: 0,
        }}
      >
        {activeTabs.map((v, i) => (
          <Tab
            value={`${match.url}${TABS[v].to}`}
            label={v}
            key={i}
            component={Link}
            to={`${match.url}${TABS[v].to}`}
            sx={{
              minWidth: 80,
              minHeight: 0,
            }}
          />
        ))}
      </Tabs>

      <div
        ref={scrollRef}
        onScroll={e => scrollEventTarget.dispatchEvent(new CustomEvent('scroll', { ...e }))}
        style={{ flex: '1', overflow: 'auto' }}
      >
        <Switch>
          {activeTabs.map((v, i) => {
            const Component = TABS[v].component
            return (
              <Route
                path={`${match.url}${TABS[v].to}`}
                render={() => (
                  <ErrorBoundary FallbackComponent={ErrorScreen}>
                    <Component
                      patient={props.patient}
                      scrollRef={scrollRef}
                      scrollEventTarget={scrollEventTarget}
                    />
                  </ErrorBoundary>
                )}
                key={i}
              />
            )
          })}
        </Switch>
      </div>
    </>
  )
}

export default PatientDetailTabs
