import care_icon from '~/assets/images/care_icon.svg'
import coverage_icon from '~/assets/images/coverage_icon.svg'
import { Badge, Chip, makeStyles, Tooltip } from '@material-ui/core'
import { ONBOARDING_STATES } from '~/constants/onboardingStates'
import { INSURANCE_COVERAGE_STATES } from '~/constants/insuranceCoverageStates'
import { differenceInDays } from 'date-fns'
import Moment from 'moment'
import { IPatientInsuranceDetails } from '~/utils/pubnub'

const ONBOARDING_STATE_TO_LABEL = {
  [ONBOARDING_STATES.signedup]: 'Signed up',
  [ONBOARDING_STATES.enrolled]: 'Signed up',
  [ONBOARDING_STATES.member]: 'Care',
  [ONBOARDING_STATES.deactivated]: 'Deactivated',
}

const INSURANCE_STATE_TO_LABEL = {
  [INSURANCE_COVERAGE_STATES.initial]: 'Eligible',
  [INSURANCE_COVERAGE_STATES.incomplete]: 'Eligible',
  [INSURANCE_COVERAGE_STATES.processing]: 'Processing',
  [INSURANCE_COVERAGE_STATES.ineligible]: 'Ineligible',
  [INSURANCE_COVERAGE_STATES.expired]: 'Expired',
  [INSURANCE_COVERAGE_STATES.covered]: 'Eligible',
}

interface PrimaryCareChipProps {
  onboardingStatus: string
  patientIsInPrimaryCareProgram: boolean
  patientIsInBenefitProgram: boolean
  styles?: any
  coverage: any
  patientInsuranceInfo: IPatientInsuranceDetails | null
  riskScore?: string | null
}
const CareIcon = (
  <img
    src={care_icon}
    alt="Primary Care"
    style={{ width: 'auto', height: '1.3rem', marginLeft: '1rem' }}
  />
)

const CoverageIcon = (
  <img
    src={coverage_icon}
    alt="Coverage"
    style={{ width: 'auto', height: '1.3rem', marginLeft: '1rem' }}
  />
)

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: '#ffd713',
    color: '#ffd713',
  },
  high: {
    backgroundColor: '#F44336',
    color: '#F44336',
  },
}))

// Displays info about the Primary Care program
const PrimaryCareChip = (props: PrimaryCareChipProps) => {
  const {
    coverage,
    onboardingStatus,
    patientIsInPrimaryCareProgram,
    patientIsInBenefitProgram,
    patientInsuranceInfo,
    riskScore,
  } = props
  const insuranceState = coverage?.status
  const classes = useStyles()
  // For care only patients care badge make sure if the onboarding status is member
  // then the member is active in primary care program
  // else show the onboarding status of the patient - discharged, deactivated, signed up and churned
  if (
    !insuranceState ||
    !onboardingStatus ||
    (ONBOARDING_STATES.member == onboardingStatus &&
      !patientIsInPrimaryCareProgram &&
      !patientIsInBenefitProgram) ||
    !patientInsuranceInfo
  )
    return null
  const insurancePayerName = patientInsuranceInfo.insurancePayerShortName
    ? patientInsuranceInfo.insurancePayerShortName
    : patientInsuranceInfo.insurancePayerName
  const insuranceCoverageStart = new Date(patientInsuranceInfo.coverageStartDate ?? '')
  const insuranceCoverageEnd = new Date(patientInsuranceInfo.coverageEndDate ?? '')
  const coverageEndDifference = differenceInDays(insuranceCoverageEnd, new Date())
  const coverageEndBadge = coverageEndDifference > 0 && coverageEndDifference <= 30 ? false : true

  const careChipVariant =
    (() => {
      if (ONBOARDING_STATES.member == onboardingStatus) {
        return 'outlined'
      } else return 'default'
    })() || 'default'

  const coverageChipVariant =
    (() => {
      if (INSURANCE_COVERAGE_STATES.covered === insuranceState) {
        return 'outlined'
      } else return 'default'
    })() || 'default'

  const coverageEndTooltip =
    (() => {
      if (coverageEndBadge == false) {
        return ' ends on ' + Moment(insuranceCoverageEnd).format('MMM. DD, YYYY')
      } else return ''
    })() || ''

  // Insurance coverage state to a readable status
  const coverageStatus =
    (() => {
      switch (true) {
        case INSURANCE_COVERAGE_STATES.covered === insuranceState:
          if (insuranceCoverageStart != null && new Date(insuranceCoverageStart) > new Date()) {
            return (
              insurancePayerName +
              ' starts ' +
              Moment(insuranceCoverageStart).format('MMM. DD, YYYY')
            )
          } else {
            return insurancePayerName
          }
        case INSURANCE_COVERAGE_STATES.processing === insuranceState &&
          [
            ONBOARDING_STATES.signedup,
            ONBOARDING_STATES.enrolled,
            ONBOARDING_STATES.member,
          ].includes(onboardingStatus) == false:
          return 'None'
        default:
          return INSURANCE_STATE_TO_LABEL[insuranceState]
      }
    })() || INSURANCE_STATE_TO_LABEL[insuranceState]

  // Onboarding state to a readable status
  const careStatus =
    (() => {
      switch (true) {
        case onboardingStatus === ONBOARDING_STATES.enrolled ||
          ((onboardingStatus === ONBOARDING_STATES.member ||
            onboardingStatus === ONBOARDING_STATES.signedup) &&
            !patientIsInPrimaryCareProgram):
          return 'None'
        default:
          return ONBOARDING_STATE_TO_LABEL[onboardingStatus]
      }
    })() || ONBOARDING_STATE_TO_LABEL[onboardingStatus]

  // Show risk score indicator only for high risk members
  const hideRiskScore = !(ONBOARDING_STATES.member == onboardingStatus) || riskScore != 'high'
  const highRiskTitle = hideRiskScore ? '' : 'High Risk'
  return (
    <>
      {careStatus != 'None' && (
        <Tooltip title={highRiskTitle} arrow placement="top">
          <Badge
            badgeContent=" "
            classes={{ badge: classes.high }}
            invisible={hideRiskScore}
            overlap="circle"
            variant="dot"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Chip
              size="small"
              variant={careChipVariant}
              label={careStatus}
              icon={CareIcon}
              title={`Primary Care: ${careStatus}`}
              style={{ marginRight: '0.3rem' }}
            />
          </Badge>
        </Tooltip>
      )}
      {coverageStatus != 'None' && (
        <Badge
          badgeContent=" "
          classes={{ badge: classes.customBadge }}
          invisible={coverageEndBadge}
          overlap="circle"
          variant="dot"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Chip
            size="small"
            variant={coverageChipVariant}
            label={coverageStatus}
            icon={CoverageIcon}
            title={`Coverage: ${coverageStatus}${coverageEndTooltip}`}
            color="default"
          />
        </Badge>
      )}
    </>
  )
}

export default PrimaryCareChip
