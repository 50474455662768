import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import provider_unavailable from '~/assets/images/provider_unavailable.svg'
import { Chip, IconButton } from '@mui/material'
import { format } from 'date-fns'
import { addDays, isBefore, isSameDay, startOfDay, startOfWeek } from 'date-fns'
import { FC, useState } from 'react'

type WeekChipsProps = {
  value: Date
  now: Date
  onChange: (date: Date) => void
  datesWithSlotAvailability: string[]
}
const ProviderUnavailableIcon = (
  <img
    src={provider_unavailable}
    alt="ProviderUnavailable"
    style={{ position: 'absolute', top: -5, right: 5 }}
  />
)

const WeekChips: FC<WeekChipsProps> = ({ value, onChange, now, datesWithSlotAvailability }) => {
  const [sow, setSow] = useState(startOfWeek(value))
  const startOfToday = startOfDay(now)
  const previousSow = addDays(sow, -7)

  return (
    <>
      <IconButton
        onClick={() => setSow(previousSow)}
        disabled={isBefore(previousSow, startOfWeek(now))}
        sx={{
          padding: 0,
        }}
      >
        <ChevronLeft />
      </IconButton>
      {[1, 2, 3, 4, 5].map(days => {
        const date = addDays(sow, days)
        const disabled = isBefore(date, startOfToday)

        return (
          <Chip
            disabled={disabled}
            icon={
              datesWithSlotAvailability.length
                ? datesWithSlotAvailability.find(
                    availableDate => availableDate === format(date, 'YYYY-MM-DD')
                  )
                  ? undefined
                  : ProviderUnavailableIcon
                : undefined
            }
            key={date.toDateString()}
            label={date.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric' })}
            onClick={() => onChange(date)}
            color={isSameDay(value, date) ? 'primary' : 'default'}
            variant="outlined"
            sx={{
              width: '17%',
              minHeight: '35px',
              borderRadius: '24px',
              padding: 0,
              '&.MuiButtonBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
              },
              '&.MuiButtonBase-root.MuiChip-colorPrimary': {
                backgroundColor: '#071F3E',
                color: 'white',
                borderColor: '#071F3E',
              },
              '&.MuiButtonBase-root.MuiChip-colorDefault': {
                backgroundColor: 'transparent',
                '&&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              },
            }}
          />
        )
      })}
      <IconButton
        onClick={() => setSow(addDays(sow, 7))}
        sx={{
          padding: 0,
        }}
      >
        <ChevronRight />
      </IconButton>
    </>
  )
}

export default WeekChips
