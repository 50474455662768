import { Box, Button, IconButton, InputBase } from '@mui/material'
import CaseMessageSearch from '~/components/Chat/CaseMessageSearch'
import { ChevronLeft, ChevronRight, Close, Search } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import { SelectedChatItem, ContentType } from '~/models/Communication'
import AddPhoneCallButton from '~/components/PhoneCall/PhoneCall'
import type { FC } from 'react'
import Chat from '~/components/Chat/Chat'
import { PatientUser } from '~/types'
import { borderProps } from '~/utils/borderProps'
import { makeStyles } from 'tss-react/mui'
import orderBy from 'lodash/orderBy'
import { useCase } from '~/api/CaseService'
import { useLocation } from 'react-router-dom'
import { CommunicationList } from '../Chat/CommunicationList'
import CurrentProfileViewers from '~/components/CurrentProfileViewers'
import { useSelector } from 'react-redux'
import { ICaseRelation } from '~/models/Case'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'

const useStyles = makeStyles()(theme => {
  return {
    arrowButton: {
      alignSelf: 'center',
      fontSize: '1.5rem',
    },
    arrowContainer: {
      justifyContent: 'center',
      display: 'flex',
      textTransform: 'none',
      color: `${theme.palette.grey[500]}`,
    },
    divider: {
      height: '50%',
      width: '2px',
      backgroundColor: `${theme.palette.grey[300]}`,
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    iconButton: {
      alignSelf: 'center',
      fontSize: '1.5rem',
      marginLeft: '1rem',
      marginRight: '1rem',
      color: `${theme.palette.grey[500]}`,
      textTransform: 'none',
    },
    container: {
      position: 'absolute' as const,
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column' as const,
    },
    messages: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
    },
    messagesInner: {
      overflow: 'visible' as const,
    },
    noMoreMessages: {
      textAlign: 'center' as const,
      fontSize: '1.2rem',
    },
  }
})

const PatientDetailChat: FC<{
  patient: PatientUser
  searchQuery: string
  lastSearchQuery: string
  isSearching: boolean
  handleSearchBar?: () => void
  handleSearchQueryChange?: React.ChangeEventHandler
  handleSearch: React.KeyboardEventHandler<HTMLInputElement>
  handleClearSearch?: React.MouseEventHandler<HTMLButtonElement>
  handleArrowSearchResults?: (threadUid: string, offset: number) => Promise<void>
}> = props => {
  const {
    patient,
    searchQuery,
    lastSearchQuery,
    isSearching,
    handleSearchBar,
    handleSearchQueryChange,
    handleSearch,
    handleClearSearch,
    handleArrowSearchResults,
  } = props
  const { classes } = useStyles()
  const location = useLocation()
  const { selectedCaseId } = useContext(SelectedTodoContext)
  const { data: selectedCase } = useCase(selectedCaseId ?? null)
  const [sortedCaseRelations, setSortedCaseRelations] = useState<ICaseRelation[]>([])
  const [selectedMessage, setSelectedMessage] = useState<null | SelectedChatItem>(null)
  const [chatMessageIndex, setChatMessageIndex] = useState<number>(0)
  const searchResults = useSelector(state => state.chat.searchResults)
  const searchResultIndex = useSelector(state => state.chat.searchResultIndex) || null

  useEffect(() => {
    const caseRelations = selectedCase ? selectedCase.relations : []
    const sorted = orderBy(caseRelations, caseRelation => caseRelation.createdAt, ['asc'])
    setSortedCaseRelations(sorted)
    // Start the index at the most recently-linked message
    if (sorted) {
      setChatMessageIndex(sorted.length - 1)
    } else {
      setChatMessageIndex(0)
    }
  }, [selectedCase])

  useEffect(() => {
    // Figure out which item in the chat should be selected
    if (!sortedCaseRelations.length) {
      setSelectedMessage(null)
      return
    }

    const selectedCaseRelation = sortedCaseRelations[chatMessageIndex]
    // Message may have been removed, so index may be out of bounds.
    if (!selectedCaseRelation) {
      setSelectedMessage(null)
      return
    }

    setSelectedMessage({
      id: selectedCaseRelation.objectId,
      contentType: selectedCaseRelation.contentType as ContentType,
    })
  }, [sortedCaseRelations, chatMessageIndex])

  const threadUid = `${patient.id}.default_v1`
  const searchResultKey = `${threadUid},${lastSearchQuery}`
  const totalSearchResults =
    searchResults && searchResultKey in searchResults ? searchResults[searchResultKey].total : 0
  const isPatient = patient.id ? true : false
  return (
    <>
      <Box
        {...borderProps(['borderBottom'])}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flex={1}>
          {selectedCaseId ? (
            <CaseMessageSearch
              patient={patient}
              isSearching={isSearching}
              lastSearchQuery={lastSearchQuery}
              case={selectedCase}
              caseRelationLength={sortedCaseRelations.length}
              chatMessageIndex={chatMessageIndex}
              setChatMessageIndex={setChatMessageIndex}
            />
          ) : (
            <>
              {!isSearching ? (
                <Box display="flex" flex={1} justifyContent="space-between">
                  <>
                    {isPatient ? (
                      <Box display="flex">
                        <IconButton className={classes.iconButton} onClick={handleSearchBar}>
                          <Search />
                        </IconButton>
                      </Box>
                    ) : null}
                  </>
                  <CurrentProfileViewers patientId={patient.id} />
                </Box>
              ) : (
                <>
                  <IconButton
                    className={classes.iconButton}
                    style={{
                      backgroundColor: 'transparent',
                      cursor: 'default',
                    }}
                  >
                    <Search />
                  </IconButton>
                  <InputBase
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    placeholder="Search thread"
                    inputProps={{ 'aria-label': 'search thread' }}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    onKeyPress={handleSearch}
                    fullWidth
                  />
                  {searchResultIndex !== null && searchResultIndex >= 0 ? (
                    <>
                      {isPatient ? (
                        <>
                          <Button className={classes.iconButton} onClick={handleClearSearch}>
                            Clear
                          </Button>
                          <div className={classes.divider}> </div>
                          <div className={classes.arrowContainer}>
                            <IconButton
                              className={classes.arrowButton}
                              disabled={searchResultIndex <= 0}
                              onClick={
                                handleArrowSearchResults
                                  ? () => handleArrowSearchResults(threadUid, -1)
                                  : undefined
                              }
                            >
                              <ChevronLeft />
                            </IconButton>
                            <IconButton
                              className={classes.arrowButton}
                              disabled={searchResultIndex + 1 >= totalSearchResults}
                              onClick={
                                handleArrowSearchResults
                                  ? () => handleArrowSearchResults(threadUid, 1)
                                  : undefined
                              }
                            >
                              <ChevronRight />
                            </IconButton>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <Button className={classes.iconButton} onClick={handleClearSearch}>
                      Clear
                    </Button>
                  )}
                  <div className={classes.divider}> </div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleSearchBar ? () => handleSearchBar() : undefined}
                  >
                    <Close />
                  </IconButton>
                </>
              )}
            </>
          )}
        </Box>
        <Box display="flex">
          <AddPhoneCallButton patient={patient} />
        </Box>
      </Box>
      <Box flex="1" position="relative">
        {isPatient ? (
          <Chat
            patient={patient}
            isSearching={isSearching}
            lastSearchQuery={lastSearchQuery}
            linkedMessages={sortedCaseRelations}
            selectedMessage={selectedMessage}
          />
        ) : (
          <CommunicationList
            patient={patient}
            selectedMessage={selectedMessage}
            linkedMessages={sortedCaseRelations}
            location={location}
            classes={classes}
          />
        )}
      </Box>
    </>
  )
}

export default PatientDetailChat
