import { LimitOffsetPagination as CoreLimitOffsetPagination } from '~/legacy/core'
import { ICommunication } from '~/models/Communication'
import { apiClient } from '~/api/rest'
import { useQuery } from '~/components/Providers/ApiProvider'

interface LimitOffsetPagination<T> extends CoreLimitOffsetPagination<T> {
  next: string | null
  previous: string | null
}

/**
 * getCommunicationsForPatient
 * @param personId: user id
 * @returns filtered communications for patient
 */
const getCommunicationsForPerson = async (personId: number): Promise<ICommunication[]> => {
  const data = await apiClient.rest.get<LimitOffsetPagination<ICommunication>>(
    `/providers/me/communications/people/${personId}`
  )
  return data.results || []
}

export const listCommunicationsKey = 'listCommunications'
export const useCommunications = (variables: { personId: number }) => {
  const { personId } = variables
  return useQuery(
    [listCommunicationsKey, personId],
    () => getCommunicationsForPerson(personId),
    {},
    {
      error: 'Failed to fetch communications',
    }
  )
}
