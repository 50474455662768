import { useEffect, useState } from 'react'
import type { ReactNode } from 'react'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { apiClientWithoutRequestDecamelization as apiClientWithoutRequestDecamelization } from '~/api/rest'
import Loader from '~/components/Loader'
import { ChipTabs } from '~/components/Generic/ChipTabs'
import CancellationsTab from './CancellationsTab'
import { useQuery } from '~/components/Providers/ApiProvider'
import BookAppointmentModal from './BookAppointment/BookAppointmentModal'
import AppointmentsTab, { VISIT_REASON_TO_LABEL } from './AppointmentsTab'
import ConfirmCancelApptModal from './ConfirmCancelApptModal'
import CancelAppointmentModal from './CancelAppointmentModal'
import { Add as AddIcon } from '@mui/icons-material'
import {
  AppointmentTypeMapping,
  CanUserBookAppointmentsV2,
  VisitReason,
  getCanUserBookAppointmentsV2,
} from '~/api/AppointmentService'

interface PatientDetailAppointmentsProp {
  patient: any
}

interface TabPanelProps {
  children: ReactNode
  value: AppointmentTabTypes
  selectedTab: AppointmentTabTypes
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, selectedTab } = props
  const visible = value === selectedTab

  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`appointment-tabpanel-${value}`}
      aria-labelledby={`appointment-tab-${value}`}
    >
      {visible && <Box p={3}>{children}</Box>}
    </div>
  )
}

type AppointmentTabTypes = 'appointments' | 'cancellations'

const STATES = {
  INIT: 0,
  BOOKING: 1,
  CANCELING: 2,
  CONFIRMING_CANCEL: 3,
  DELETING: 4,
} as const

export type ApptTypes =
  | 'Medical Issue'
  | 'Annual Wellness'
  | 'Health Guide Visit'
  | 'Behavioral Health Visit'
  | 'Annual Wellness '
  | 'Annual Wellness New'

export const VisitTypeMapping: [VisitReason, ApptTypes][] = [
  ['video', 'Medical Issue'],
  ['videoNew', 'Medical Issue'],
  ['annualWellness', 'Annual Wellness'],
  ['healthGuideConsult', 'Health Guide Visit'],
  ['behavioralHealth', 'Behavioral Health Visit'],
  ['annualWellnessVisitEstablished', 'Annual Wellness '],
  ['annualWellnessVisitNew', 'Annual Wellness New'],
]

export const useGetPatientAppointmentsInfo = (patientId: number) =>
  useQuery(
    ['getPatientAppointmentsInfo', patientId],
    async () => {
      // TODO: the type here is BffCrmPatientAppointmentsRetrieveResponse
      // but imports from @fireflyhealth/api are not working (Module parse failed...)
      return await apiClientWithoutRequestDecamelization.rest.get<any>(
        `/bff/crm/patient-appointments/?patient_id=${patientId}`
      )
    },
    { staleTime: Infinity, enabled: !!patientId }
  )

export const useGetPatientAppointmentCancelationsInfo = (patientId: number) =>
  useQuery(
    ['getPatientAppointmentCancelationInfo', patientId],
    async () => {
      // TODO: the type here is BffCrmPatientAppointmentsRetrieveResponse
      // but imports from @fireflyhealth/api are not working (Module parse failed...)
      return await apiClientWithoutRequestDecamelization.rest.get<any>(
        `/bff/crm/patient-appointment-cancelations/?patient_id=${patientId}`
      )
    },
    { staleTime: Infinity, enabled: !!patientId }
  )

export type Appointment = Awaited<ReturnType<typeof useGetPatientAppointmentsInfo>>['data']
export type AppointmentCancelation = Awaited<
  ReturnType<typeof useGetPatientAppointmentCancelationsInfo>
>['data']

const PatientDetailAppointments = (props: PatientDetailAppointmentsProp) => {
  const [userVisitTypes, setUserVisitTypes] = useState<CanUserBookAppointmentsV2>()
  useQuery(
    ['getCanUserBookAppointmentsV2', props.patient.id],
    () => getCanUserBookAppointmentsV2(props.patient.id),
    {
      onSuccess(userTypes) {
        setUserVisitTypes(userTypes)
      },
    }
  )

  const { data: appointments, isLoading: loadingAppointments } = useGetPatientAppointmentsInfo(
    props.patient.id
  )
  const { data: appointmentCancelations, isLoading: loadingAppointmentCancelations } =
    useGetPatientAppointmentCancelationsInfo(props.patient.id)

  const [tab, setTab] = useState<AppointmentTabTypes>('appointments')
  const [currentAppointment, setCurrentAppointment] = useState<Appointment | null>(null)
  const [state, setState] = useState<typeof STATES[keyof typeof STATES]>(STATES.INIT)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // Cancelation state
  const [otherReasonForFirefly, setOtherReasonForFirefly] = useState<string>('')
  const [cancellationReasonIds, setCancellationReasonIds] = useState<Array<number>>([])
  const [otherReasonForPatient, setOtherReasonForPatient] = useState<string>('')
  const [selectedVisitType, setSelectedVisitType] = useState<VisitReason | null>(null)
  const [visitTypeTitle, setSelectedVisitTypeTitle] = useState<ApptTypes | ''>('')

  useEffect(() => {
    if (state === STATES.INIT) {
      setCurrentAppointment(null)
      setCancellationReasonIds([])
      setOtherReasonForPatient('')
      setOtherReasonForFirefly('')
    }
  }, [state])

  if (loadingAppointments) {
    return <Loader />
  }

  const handleBookAppt = (e, visitReason, apptType) => {
    setState(STATES.BOOKING)
    setSelectedVisitType(visitReason)
    setSelectedVisitTypeTitle(apptType)
  }

  const cancelations =
    !appointmentCancelations || !appointmentCancelations.cancelationEvents
      ? 0
      : appointmentCancelations.cancelationEvents.length
  let visitTypesAllowed: string[] = []
  if (userVisitTypes) {
    Object.keys(userVisitTypes).forEach(key => {
      if (userVisitTypes[key]) {
        visitTypesAllowed.push(key)
      }
    })
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={2}>
        <ChipTabs
          tabs={[
            { label: `Appointments (${appointments.appointments.length})`, value: 'appointments' },
            {
              label: `Cancellations (${cancelations})`,
              value: 'cancellations',
            },
          ]}
          selectedTab={tab}
          onSelect={setTab}
        />

        <Box>
          <IconButton
            color="primary"
            onClick={e => {
              if (visitTypesAllowed.length > 1) {
                setAnchorEl(e.currentTarget)
              } else if (visitTypesAllowed.length === 1) {
                handleBookAppt(
                  e,
                  visitTypesAllowed[0],
                  VISIT_REASON_TO_LABEL[AppointmentTypeMapping[visitTypesAllowed[0]]]
                )
                setAnchorEl(null)
              } else {
                handleBookAppt(e, null, 'Appointment')
                setAnchorEl(null)
              }
            }}
            sx={theme => ({
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary[700],
              },
              borderRadius: 'unset',
            })}
          >
            <AddIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Book appointment</Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={e => {
              setAnchorEl(null)
            }}
            MenuListProps={{
              disablePadding: true,
            }}
            disableEnforceFocus
            disableRestoreFocus
          >
            {VisitTypeMapping.map(([visitReason, apptType]) => {
              return userVisitTypes && userVisitTypes[visitReason] ? (
                <MenuItem
                  key={visitReason}
                  onClick={e => {
                    handleBookAppt(e, visitReason, apptType)
                    setAnchorEl(null)
                  }}
                >
                  {apptType}
                </MenuItem>
              ) : null
            })}
          </Menu>
        </Box>
      </Box>

      <TabPanel value="appointments" selectedTab={tab}>
        <AppointmentsTab
          patientId={props.patient.id}
          data={appointments}
          isLoading={loadingAppointments}
          selectAppointmentForCancel={appointment => {
            setCurrentAppointment(appointment)
            setState(STATES.CANCELING)
          }}
        />
      </TabPanel>

      <TabPanel value="cancellations" selectedTab={tab}>
        <CancellationsTab
          patientId={props.patient.id}
          data={appointmentCancelations}
          isLoading={loadingAppointmentCancelations}
          selectAppointmentForCancel={appointment => {
            setCurrentAppointment(appointment)
            setState(STATES.CANCELING)
          }}
        />
      </TabPanel>
      {state === STATES.BOOKING ? (
        <BookAppointmentModal
          personId={props.patient.person.id}
          patientId={props.patient.id}
          onClose={() => setState(STATES.INIT)}
          visitType={selectedVisitType}
          visitTypeTitle={visitTypeTitle}
        ></BookAppointmentModal>
      ) : null}
      {state === STATES.CANCELING ? (
        <CancelAppointmentModal
          appointment={currentAppointment}
          cancellationReasonIds={cancellationReasonIds}
          setCancellationReasonIds={setCancellationReasonIds}
          otherReasonForPatient={otherReasonForPatient}
          otherReasonForFirefly={otherReasonForFirefly}
          setOtherReasonForPatient={setOtherReasonForPatient}
          setOtherReasonForFirefly={setOtherReasonForFirefly}
          onClose={success =>
            success ? setState(STATES.CONFIRMING_CANCEL) : setState(STATES.INIT)
          }
        />
      ) : null}
      {state === STATES.CONFIRMING_CANCEL ? (
        <ConfirmCancelApptModal
          appointment={currentAppointment}
          cancellationReasonIds={cancellationReasonIds}
          otherReasonForPatient={otherReasonForPatient}
          otherReasonForFirefly={otherReasonForFirefly}
          onClose={continueToBooking =>
            continueToBooking ? setState(STATES.BOOKING) : setState(STATES.INIT)
          }
        />
      ) : null}
    </Box>
  )
}

export default PatientDetailAppointments
