import { Box } from '@mui/material'
import { FC, useEffect } from 'react'
import { endOfDay } from 'date-fns'
import {
  AvailablePhysicianSlot,
  availablePhysiciansWithSlotsV2,
  VisitReason,
} from '~/api/AppointmentService'
import SelectDate from './SelectDate'
import { useQuery } from 'react-query'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { DYNAMIC_SCHEDULE_V2 } from '~/components/Appointment/utils'
import PhysicianSlotPickerGroupsByDay from './PhysicianSlotsPickerGroupsByDay'

type BookAppointmentByDayTabProps = {
  patientId: number
  personId?: number
  visitReason: VisitReason | null
  now: Date
  date: Date
  setDate: (value: Date) => void
  slot: AvailablePhysicianSlot | null
  setSlot: (value: AvailablePhysicianSlot) => void
  setIsLoading: (e: boolean) => void
  isLoading?: boolean
}

const BookAppointmentByDayTab: FC<BookAppointmentByDayTabProps> = ({
  patientId,
  visitReason,
  date,
  setDate,
  now,
  slot,
  setSlot,
  setIsLoading,
}) => {
  const isDynamicSchedulingV2Enabled: boolean = useFeatureFlag(DYNAMIC_SCHEDULE_V2)
  const query = useQuery(
    ['availablePhysiciansWithSlotsV2', visitReason, date],
    () => availablePhysiciansWithSlotsV2(patientId, visitReason!, date, endOfDay(date)),
    {
      onSuccess() {
        setIsLoading(false)
      },
    }
  )

  useEffect(() => {
    if (query.isLoading || query.isIdle) {
      setIsLoading(true)
    }

    if (query.isError) {
      console.log({ error: query.error })
      setIsLoading(false)
    }
  }, [query])

  if (query.isLoading || query.isIdle || query.isError) {
    return null
  }

  return (
    <>
      <Box>
        <SelectDate
          date={date}
          setDate={setDate}
          now={now}
          datesWithSlotAvailability={query.data.datesWithSlotAvailability}
        />

        <PhysicianSlotPickerGroupsByDay
          setDate={setDate}
          slots={isDynamicSchedulingV2Enabled ? query.data.appointments : query.data}
          value={slot}
          onChange={setSlot}
        />
      </Box>
    </>
  )
}

export default BookAppointmentByDayTab
