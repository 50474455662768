import { Box, Typography } from '@mui/material'
import { ICase } from '~/models/Case'
import EmailIcon from '@mui/icons-material/Email'
import { makeStyles } from 'tss-react/mui'
import { styles } from '../Chat/styles'
import { IEmailMessage } from '../Chat/Chat'
import { messageDateInfo } from '../Chat/utils'
import { useState } from 'react'
import { EmailListView } from './EmailListView'

const useStyles = makeStyles()(theme => {
  return {
    message: styles.message(theme),
    messageDate: styles.messageDate(theme),
  }
})

const EmailChatMessage = (props: { email: IEmailMessage; emailCases?: ICase[]; key: string }) => {
  const { email } = props
  const { classes } = useStyles()
  const [showEmailModel, setShowEmailModel] = useState<boolean>(false)
  const caseId = email.cases[0].id

  const sender = email.sender

  const { messageDate, preciseDate } = messageDateInfo(email)

  return (
    <Box mt={2} key={props.key}>
      <Box
        className={classes.message}
        title={messageDate}
        data-id={`email-${email.id}`}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        sx={{
          position: 'relative',
          '&:hover .message-actions-hover-control': {
            opacity: '1 !important',
          },
          borderRadius: 2,
          backgroundColor: 'background.highlight',
        }}
      >
        {/* Left: avatar */}
        <Box>
          <EmailIcon sx={{ marginLeft: 1 }} />
        </Box>
        {/* Right: message contents */}
        <Box flexGrow={1}>
          <Box display="flex" flexDirection={'row'} mb={0.5}>
            <Typography title={preciseDate} sx={{ fontSize: '1.2rem', marginLeft: 3 }}>
              {messageDate}
            </Typography>
          </Box>
          <Box
            position="relative"
            tabIndex={0}
            marginLeft={3}
            sx={{
              justifyContent: 'flex-start',
              display: 'flex',
              flexWrap: 'wrap',
              position: 'relative',
              justifyItems: 'center',
            }}
          >
            <Box
              onClick={() => setShowEmailModel(true)}
              sx={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 16,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                backgroundColor: 'primary.100',
                color: 'text.primary',
                ['& a']: {
                  color: 'primary.main',
                },
                position: 'relative',
                padding: 1.25,
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontSize: '1.6rem',
                cursor: 'move',
              }}
            >
              <Typography>{sender} sent an email</Typography>
              <Typography sx={{ textDecoration: 'underline', color: '#0077DC' }}>
                Email Thread
              </Typography>
            </Box>
          </Box>
          {showEmailModel && (
            <EmailListView selectedCaseId={caseId} onClose={() => setShowEmailModel(false)} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EmailChatMessage
