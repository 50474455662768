import type { FC } from 'react'
import { Autocomplete, TextField, Checkbox as MuiCheckbox } from '@mui/material'
import {
  CheckBoxOutlineBlank as MuiIconCheckBoxOutlineBlank,
  CheckBox as MuiIconCheckBox,
} from '@mui/icons-material'
import { ClinicalFocusArea } from '../types'

export interface ClinicalFocusAreaType {
  id: number
  clinicalFocusArea: string
  category: string
}

export interface ClinicalFocusAreaAutocompleteProps {
  onChangeClinicalFocusArea: (value: ClinicalFocusAreaType[]) => void
  clinicalFocusAreas: ClinicalFocusArea[]
  selectedClinicalFocusAreas: ClinicalFocusArea[]
  disabled?: boolean
}

const ClinicalFocusAreaAutocomplete: FC<ClinicalFocusAreaAutocompleteProps> = props => {
  const selectedClinicalFocusArea = props?.selectedClinicalFocusAreas.map(option => {
    return {
      category: option?.category?.description,
      id: option.id,
      clinicalFocusArea: option.label,
    } as ClinicalFocusAreaType
  })
  return (
    <Autocomplete
      disabled={props?.disabled}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple
      disableCloseOnSelect
      filterSelectedOptions
      size="small"
      options={props?.clinicalFocusAreas?.map(
        option =>
          ({
            key: option.id,
            category: option?.category?.description,
            id: option.id,
            clinicalFocusArea: option.label,
          } as ClinicalFocusAreaType)
      )}
      groupBy={option => option.category}
      getOptionLabel={option => option.clinicalFocusArea}
      isOptionEqualToValue={(option, value) =>
        option.category == value.category && option.id == value.id
      }
      renderInput={params => (
        <TextField variant="standard" {...params} label={'Clinical Focus Areas'} />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <MuiCheckbox
            icon={<MuiIconCheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<MuiIconCheckBox fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.clinicalFocusArea}
        </li>
      )}
      value={selectedClinicalFocusArea}
      onChange={(event, value) => {
        props?.onChangeClinicalFocusArea(value)
      }}
    />
  )
}

export default ClinicalFocusAreaAutocomplete
