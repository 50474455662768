import { FC, useState } from 'react'
import { Box, Button, Dialog, Divider, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { EmailMessageDisplay } from './EmailMessageDisplay'
import { EmailEditor } from './EmailEditor'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import { SEND_EMAIL_FEATURE_FLAG } from './constants'
import { useCaseEmail } from '~/api/CaseService'
import Loader from '../Loader'
import { ICaseEmail } from '~/models/Case'

interface EmailListViewProps {
  selectedCaseId: number
  onClose: () => void
}

const EmailList: FC<{ subject: string; linkedEmailContent: ICaseEmail[] }> = props => {
  return (
    <>
      <Typography fontSize={13} fontWeight="bold">
        {props.subject}
      </Typography>
      {props.linkedEmailContent.map((email, i) => (
        <>
          <EmailMessageDisplay
            content={email.message}
            key={email.id}
            to_address={email.toAddress}
            from_address={email.fromAddress}
            send_at={email.emailSentAt ? email.emailSentAt : email.createdAt}
            maxLength={200}
            status={email.status}
          />
          {/* Divider should not been shown to last email*/}
          {i < props.linkedEmailContent.length - 1 && <Divider key={i} />}
        </>
      ))}
    </>
  )
}

export const EmailListView: FC<EmailListViewProps> = props => {
  const { result: linkedEmailContent, isLoading } = useCaseEmail(props.selectedCaseId)
  const [sendEmail, setSendEmail] = useState<boolean>(false)
  // seperate feature flag is used here to block the send functionaly,
  // since send should be enabled only for health plan ops team and
  // everyone else should be just view the email, hence a switch is used for viewing email.
  const isCaseSendEmailEnabled = useFeatureFlag(SEND_EMAIL_FEATURE_FLAG)
  return (
    <Dialog
      open={true}
      onClose={(e: React.MouseEvent) => {
        e.stopPropagation()
        props.onClose()
      }}
      fullWidth={true}
    >
      <Box display="flex" flexDirection="column" px={4} py={3} flexGrow={1}>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">External Email</Typography>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {linkedEmailContent && linkedEmailContent.length > 0 && (
              <EmailList
                subject={linkedEmailContent[0].subject}
                linkedEmailContent={linkedEmailContent}
              />
            )}
            <Box
              flex={1}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {isCaseSendEmailEnabled && !sendEmail && linkedEmailContent.length > 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    borderRadius: 10,
                    fontSize: '1.4rem',
                  }}
                  onClick={() => setSendEmail(true)}
                >
                  Reply
                </Button>
              )}
              {isCaseSendEmailEnabled &&
                props.selectedCaseId &&
                (sendEmail || linkedEmailContent.length === 0) && (
                  <EmailEditor
                    content={''}
                    case_id={props.selectedCaseId}
                    onCancel={() => setSendEmail(false)}
                  />
                )}
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  )
}
