import { apiClient } from '~/api/rest'
import { keyBy } from 'lodash/fp'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

// Corresponds to Waffle Flags on backend
interface FeatureFlag {
  id: number
  name: string
  note: string
  users: number[]
  isActive: boolean
}

export const FEATURE_FLAG_QUERY_KEY = ['featureFlags']

const featureFlagsRequest = () => apiClient.rest.get<FeatureFlag[]>('/feature/provider/flags/')

export const useFeatureFlags = () => {
  const me = useSelector(state => state.me)

  return useQuery(FEATURE_FLAG_QUERY_KEY, featureFlagsRequest, {
    staleTime: Infinity,
    enabled: me?.id != null,
    select: data => ({
      featureFlagsByName: keyBy('name', data),
    }),
  })
}

export const useFeatureFlag = name => {
  const { data } = useFeatureFlags()
  return !!data?.featureFlagsByName?.[name]?.isActive
}
