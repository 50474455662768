import { useMemo } from 'react'
import { apiClient } from '~/api/rest'
import { LimitOffsetPagination } from '~/legacy/core'
import { useQuery, useMutation } from '~/components/Providers/ApiProvider'
import { ICase, ICaseCategory, ICaseEmail } from '~/models/Case'
import { keyBy } from 'lodash'
import { QueryClient, useQueryClient } from 'react-query'
import { listPersonTodoKey } from './PatientToDoService'
import { getInboxKey } from './InboxService'
import { mapPreviewTasksToTasks } from '~/utils/tasks'
import { ITask } from '~/models/Task'
import { setDueDateToEOD } from '~/utils/date'

async function listCaseCategories(): Promise<ICaseCategory[]> {
  // TODO: Search once we have more categories than we can show in a single select input.
  const data = await apiClient.rest.get<LimitOffsetPagination<ICaseCategory>>(
    `/providers/me/cases/categories/`
  )
  return data.results
}

const listCaseCategoriesKey = 'listCaseCategories'
export const useCaseCategories = () => {
  const { data, isLoading } = useQuery(
    [listCaseCategoriesKey],
    listCaseCategories,
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch case categories',
    }
  )

  return { result: data || [], isLoading }
}

async function listCaseEmail(caseId: number): Promise<ICaseEmail[]> {
  const data = await apiClient.rest.get<{ emails: ICaseEmail[] }>(
    `/providers/me/cases/${caseId}/email/`
  )
  return data.emails
}

export const listCaseEmailKey = 'listCaseEmail'
export const useCaseEmail = caseId => {
  const { data, isLoading } = useQuery(
    [listCaseEmailKey, caseId],
    () => listCaseEmail(caseId),
    {
      staleTime: 0.5 * 60 * 1000, // 30 sec
      enabled: caseId !== null,
    },
    {
      error: 'Failed to fetch case email',
    }
  )

  return { result: data || [], isLoading }
}

export const useCaseCategoriesById = () => {
  const { result: categories, isLoading } = useCaseCategories()
  const result = useMemo(() => {
    return keyBy(categories, category => category.id)
  }, [categories, isLoading])

  return { result, isLoading }
}

// IClientCase represents a case on the client that has yet to be persisted.
type IClientCase = Omit<ICase, 'id' | 'createdAt' | 'updatedAt'>

function addPatientCase(payload: IClientCase): Promise<ICase> {
  return apiClient.rest.post<ICase>(`/providers/me/cases/`, {
    ...payload,
    // Due date will be Converted as selected date with 19:00 EST time
    dueDate: payload.dueDate ? setDueDateToEOD(payload.dueDate) : undefined,
    tasks: payload.tasks?.map(task => ({
      ...task,
      // Due date will be Converted as selected date with 19:00 EST time
      dueDate: setDueDateToEOD(task.dueDate),
    })),
  })
}

export const useAddCase = () => {
  const client = useQueryClient()

  return useMutation(
    addPatientCase,
    {
      onSuccess: async (c: ICase) => {
        client.invalidateQueries([listCasesKey, c.person])
        client.invalidateQueries([listPersonTodoKey, c.person])
      },
    },
    { error: 'Failed to add case' }
  )
}

const getCase = async (id: number): Promise<ICase> =>
  apiClient.rest.get<ICase>(`/providers/me/cases/${id}`)

export const getCaseKey = 'getCase'
export const useCase = (id: number | null) => {
  return useQuery(
    [getCaseKey, id],
    () => getCase(id!), // `enabled` guarantees this will not be null
    {
      enabled: id !== null,
    },
    {
      error: 'Failed to fetch case',
    }
  )
}

const getCaseEvents = async (id: number) =>
  apiClient.rest.get<
    LimitOffsetPagination<{
      pghId: number
      pghTable: string
      pghCreatedAt: string
      pghData: object
      pghLabel: string
      pghDiff: object
      pghContext: {
        metadata: {
          user?: {
            id: number
            firstName: string | null
            lastName: string | null
          }
        }
      }
    }>
  >(`/providers/me/cases/${id}/events/`)

export const useCaseEvents = (id: number) => {
  return useQuery(
    ['getCaseEvents', id],
    () => getCaseEvents(id),
    {},
    {
      error: 'Failed to fetch case history',
    }
  )
}

const getCaseMessageTranscript = async (id: number) =>
  apiClient.rest.get<{
    text: string
  }>(`/providers/me/cases/${id}/message-transcript/`)

export const useCaseMessageTranscript = (id: number) => {
  return useQuery(
    ['getCaseMessageTranscript', id],
    () => getCaseMessageTranscript(id),
    {},
    {
      error: 'Failed to fetch case message transcript',
    }
  )
}

/**
 * getCasesForPatient
 * @param personId: person id
 * @returns filtered cases for patient
 */
const getCasesForPatient = async (personId: number): Promise<ICase[]> => {
  const data = await apiClient.rest.get<LimitOffsetPagination<ICase>>(
    `/providers/me/cases/?person=${personId}`
  )
  return data.results || []
}

export const listCasesKey = 'listCases'
export const useCases = (variables: { personId: number }) => {
  const { personId } = variables
  return useQuery(
    [listCasesKey, personId],
    () => getCasesForPatient(personId),
    {
      retryDelay: 500,
      retry: 3,
    },
    {
      error: 'Failed to fetch cases',
    }
  )
}

function updatePatientCase(
  payload: Required<Pick<ICase, 'id'>> & Partial<Omit<ICase, 'id'>>
): Promise<ICase> {
  return apiClient.rest.patch<ICase>(`/providers/me/cases/${payload.id}`, {
    ...payload,
    // Due date will be Converted as selected date with 19:00 EST time
    dueDate: payload.dueDate ? setDueDateToEOD(payload.dueDate) : undefined,
    tasks: payload.tasks?.map(task => ({
      ...task,
      // Due date will be Converted as selected date with 19:00 EST time
      dueDate: task.dueDate ? setDueDateToEOD(task.dueDate) : undefined,
    })),
  })
}

export const invalidateCachedCaseData = (client: QueryClient, personId: number) => {
  return Promise.all([
    client.invalidateQueries([listCasesKey, personId]),
    client.invalidateQueries([listPersonTodoKey, personId]),
    client.invalidateQueries([getCaseKey]),
    client.invalidateQueries([getInboxKey]),
  ])
}

export const useAddNewCaseRelation = (personId: number) => {
  const client = useQueryClient()

  return useMutation(
    ({
      caseObj,
      relationData,
    }: {
      caseObj: { id: number }
      relationData: { contentType: string; objectId: number }
    }) =>
      apiClient.rest.patch(`/providers/me/cases/${caseObj.id}/add-relation/`, {
        newRelation: relationData,
      }),
    {
      onSuccess: async (_caseObj: ICase) => {
        await invalidateCachedCaseData(client, personId)
      },
    },
    { error: 'Failed to update case' }
  )
}

export const useUpdateCase = () => {
  const client = useQueryClient()

  return useMutation(
    updatePatientCase,
    {
      onSuccess: async (caseObj: ICase) => {
        await invalidateCachedCaseData(client, caseObj.person)
      },
    },
    { error: 'Failed to update case' }
  )
}

function deletePatientCase(
  payload: Required<Pick<ICase, 'id'>> & Partial<Omit<ICase, 'id'>>
): Promise<ICase> {
  return apiClient.rest.delete<ICase>(`/providers/me/cases/${payload.id}`)
}

export const useDeleteCase = (personId: number) => {
  const client = useQueryClient()
  return useMutation(
    deletePatientCase,
    {
      onSuccess: () => {
        client.invalidateQueries([listCasesKey, personId])
        client.invalidateQueries([listPersonTodoKey, personId])
        client.invalidateQueries([getInboxKey])
      },
    },
    { error: 'Failed to delete case' }
  )
}

export const useTaskCollectionPreviewMutation = () => {
  return useMutation(
    async (args: {
      caseCategoryId: number
      userId: number
      me: number
      meAssigneeGroup: number
    }) => {
      const result: {
        taskCollectionPk: number | null
        taskPreviews: ITask[]
      } = await apiClient.rest.get(
        `/providers/me/cases/from-category/${args.caseCategoryId}/preview/?user_id=${args.userId}`
      )
      if (result && result.taskPreviews) {
        result.taskPreviews = mapPreviewTasksToTasks(result.taskPreviews, {
          defaultAssigneeGroup: args.meAssigneeGroup,
        })
      }
      return result
    },
    {},
    { error: "Failed to fetch Case Category's tasks" }
  )
}
